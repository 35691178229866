import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Flex, Text, Image } from "rebass";
import { useForm } from "react-hook-form";

import { authHeader, checkSessionByName, sessionName } from "../session";
import { apiService } from "../apiService";
import { hamdSeverity } from "../models/severity";

import NavBar from "../Components/Navbar";
import dialIcon from "../Assets/dial-icon.svg";
import phoneIcon from "../Assets/phone-icon.svg";
import pinIcon from "../Assets/pin-icon.svg";
import MentalInput from "../Components/MentalInput";
import MentalButton from "../Components/MentalButton";

const PersonalInfoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [diagnosisResult, setDiagnosisResult] = useState(null);
  const [severity, setSeverity] = useState(hamdSeverity.NORMAL);

  const [provinceList, setProvinceList] = useState([]);
  const [province, setProvince] = useState("");
  const [showError, setShowError] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [searchInputText, setSearchInputText] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm();

  useEffect(() => {
    // const checkUserSession = async () => {
    //   await checkSessionByName(sessionName.MENTAL, navigate, "/");
    // };
    // checkUserSession();

    const userData = JSON.parse(localStorage.getItem("user"));
    const result = JSON.parse(localStorage.getItem("diagnosis_result"));

    if (userData && result) {
      setUser(userData);
      setDiagnosisResult(result);
    } else {
      navigate("/");
      return;
    }

    setSeverity(location.state?.severity ?? hamdSeverity.NORMAL);

    getProvinces();
  }, [location]);

  const getProvinces = async () => {
    await apiService
      .get(`/mental/provinces`)
      .then((response) => {
        const responseData = response.data;
        const provinces = _.map(responseData.provinces, (p) => {
          return { name: p };
        });
        setProvinceList(provinces);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (data) => {
    setDisableButton(true);
    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
    if (province === "") {
      setShowError(true);
    } else {
      updatePersonalInfo(data);
    }
  };

  const updatePersonalInfo = async (data) => {
    if (!diagnosisResult) return;
    let req = {
      phone_number: data.phoneNumber,
      province: province,
    };
    await apiService
      .post(
        `/mental/mental_records/${diagnosisResult.record_id}/update_phone`,
        req,
        authHeader()
      )
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === "success") {
          navigate("/feedback-rating", {
            state: { severity },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterProvince = () => {
    const regExp = new RegExp(searchInputText, "gi");
    return _.filter(provinceList, (p) => p.name.match(regExp));
  };

  const onClickDenyContact = () => {
    navigate("/feedback-rating", {
      state: { severity: hamdSeverity.MODERATE, consent: true },
    });
  };

  return (
    <Flex
      as="form"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      maxWidth={768}
      minHeight="100vh"
      pb={40}
      css={{
        background: "#FDFEF9",
        position: "relative",
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex width={"90%"} flexDirection="column" alignItems="center" pt={20}>
        {/* Details */}
        <NavBar backOnly />
        <Image src={dialIcon} />
        <Text
          mt={3}
          fontFamily="Mitr"
          fontSize="1.2rem"
          fontWeight={500}
          color="#1E1446"
          textAlign="center"
        >
          ขอข้อมูลเพิ่มเติม <br />
          เพื่อให้เจ้าหน้าที่ติดต่อกลับ
        </Text>

        {/* Form */}
        <Flex flexDirection="column" width={1} maxWidth={400}>
          <MentalInput
            mt={60}
            headerLabel
            labelText="เบอร์ติดต่อ"
            lableImage={phoneIcon}
            type="number"
            textAlign="left"
            {...register("phoneNumber", {
              required: true,
              pattern:
                /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/gm,
              maxLength: 10,
            })}
          />
          {errors.phoneNumber && (
            <p
              style={{
                color: "#D21F1F",
                fontFamily: "Mitr",
              }}
            >
              ข้อมูลไม่ถูกต้อง
            </p>
          )}
          <Flex width={1} flexDirection="column" css={{ position: "relative" }}>
            <MentalInput
              mt={4}
              headerLabel
              labelText="จังหวัดที่อยู่"
              lableImage={pinIcon}
              onChange={(e) => {
                setSearchInputText(e.target.value);
              }}
              value={searchInputText}
              textAlign="left"
              onFocus={() => {
                setProvince("");
                setSearchInputText("");
                setOpenDropDown(true);
              }}
            />
            {showError && (
              <p
                style={{
                  visibility: province.length > 0 ? "hidden" : "visible",
                  color: "#D21F1F",
                  fontFamily: "Mitr",
                }}
              >
                กรุณาระบุจังหวัด
              </p>
            )}
            <Flex
              display={openDropDown ? "flex" : "none !important"}
              mt={1}
              flexDirection="column"
              backgroundColor="#fff"
              width={"80%"}
              height="auto"
              maxHeight={250}
              css={{
                border:
                  filterProvince().length === 0 ? "0px" : "1px solid #000",
                borderRadius: ".6rem",
                zIndex: 3,
                overflowY: "scroll",
                position: "absolute",
                top: 106,
              }}
            >
              {filterProvince().map((p, i) => (
                <Flex
                  key={i}
                  width={1}
                  py={2}
                  textAlign="center"
                  justifyContent="center"
                  css={{
                    border: "1px solid #F0F0F0",
                    fontFamily: "Mitr",
                  }}
                  onClick={() => {
                    setProvince(p.name);
                    setSearchInputText(p.name);
                    setOpenDropDown(false);
                  }}
                >
                  {p.name}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {/* Bottom View */}
      <Flex flexDirection="column" width={"90%"}>
        <MentalButton
          type="submit"
          width={1}
          disabled={disableButton}
          backgroundColor="#1E1446"
          textColor="#F2E1C1"
          mt={40}
          text="ยืนยัน"
        />
        <MentalButton
          width={1}
          backgroundColor="#fff"
          border="2px solid #F2E1C1"
          textColor="#1E1446"
          mt={3}
          text="ปฎิเสธการติดต่อกลับ"
          onClick={onClickDenyContact}
        />
      </Flex>
    </Flex>
  );
};

export default PersonalInfoPage;
