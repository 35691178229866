import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Checkbox, Input, Label } from "@rebass/forms";
import { Button, Flex, Image } from "rebass";

import { eightQSeverity } from "../../../models/severity";
import severity_icon from "../../../Assets/severity-icon.svg";
import arrowDownIcon from "../../../Assets/arrow-down-icon.svg";

const severityData = [
  {
    text: "Severe",
    selected: false,
  },
  {
    text: "Moderate",
    selected: false,
  },
  {
    text: "Mild",
    selected: false,
  },
];

const SeverityDropDown = ({ hidden, selectedSeverityLevels, onApply }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedAllSeverity, setSelectedAllSeverity] = useState(false);
  const [selectedSeverity, setSelectedSeverity] = useState({});
  const [severityText, setSeverityText] = useState([]);

  const { register, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setInitialSeverityState();

    if (selectedSeverityLevels.length === 3) {
      setValue("all_severity", true);
      setSelectedAllSeverity(true);
    } else {
      let severityLevels = {};
      let severities = [];
      _.forEach(selectedSeverityLevels, (severity) => {
        setValue(`severity_${severity}`, true);
        severityLevels = {
          ...severityLevels,
          [severity]: { value: severity, selected: true },
        };

        severities.push(severity.toLowerCase());
      });

      setSelectedSeverity(severityLevels);
      setSeverityText(severities.join(", "));
    }
  }, [selectedSeverityLevels]);

  const setInitialSeverityState = () => {
    let severityLevels = {};
    _.forEach(severityData, (severity) => {
      const key = severity.text.toUpperCase();
      setValue(`severity_${key}`, false);
      severityLevels = {
        ...severityLevels,
        [key]: { value: key, selected: false },
      };
    });
    setSelectedSeverity(severityLevels);
  };

  const onClickSelectedSeverity = (checked, key) => {
    setSelectedSeverity({
      ...selectedSeverity,
      [key]: { value: key, selected: checked },
    });
  };

  const onClickApply = () => {
    let severities = [];
    const selectedSeverityData = _.filter(selectedSeverity, (s) => s.selected);
    _.forEach(selectedSeverityData, (selected) => {
      severities.push(selected.value.toUpperCase());
    });

    if (selectedSeverityData.length === 0) {
      onApply([eightQSeverity.SEVERE, eightQSeverity.MODERATE]);
    } else if (selectedAllSeverity || selectedSeverityData.length === 3) {
      setSelectedSeverity({});
      onApply([]);
    } else {
      setSeverityText(severities);
      onApply(severities);
    }
    setShowDropDown(false);
  };

  return (
    <Flex
      flexDirection="column"
      mx={2}
      mt={12}
      css={{
        position: "relative",
        display: hidden ? "none !important" : "flex",
      }}
    >
      <Label
        minWidth={200}
        maxWidth={250}
        minHeight={38}
        px={2}
        alignItems="center"
        css={{
          boxShadow:
            "0px 0.0851744px 0.255523px rgba(0, 0, 0, 0.1), 0px 0.851744px 1.70349px rgba(0, 0, 0, 0.2)",
          borderRadius: ".5rem",
        }}
      >
        <Image width={26} src={severity_icon} />
        <Input
          placeholder="ระดับความรุนแรง"
          readOnly
          value={selectedAllSeverity ? "(ทั้งหมด)" : severityText}
          css={{
            border: "none",
            outline: "none",
            fontFamily: "Mitr",
            fontWeight: 400,
            fontSize: ".9rem",
            color: "#1034A6",
            "::placeholder": {
              color: "rgba(16, 52, 166, 0.5)",
            },
            cursor: "pointer",
            textTransform: "capitalize",
          }}
          onClick={() => {
            setShowDropDown(!showDropDown);
          }}
        />
        <Image width={16} src={arrowDownIcon} />
      </Label>
      <Flex
        display={showDropDown ? "flex" : "none !important"}
        mt={1}
        pt={2}
        flexDirection="column"
        width={200}
        maxHeight={500}
        alignItems="center"
        css={{
          boxShadow: "0px 4px 30px rgba(37, 37, 37, 0.1)",
          background: "#fff",
          zIndex: 4,
          overflow: "scroll",
          position: "absolute",
          fontFamily: "Mitr",
          top: 40,
          borderRadius: "6px",
        }}
      >
        <Flex
          py={2}
          width={1}
          pl={1}
          justifyContent="center"
          css={{ borderBottom: "1px solid #D0DAE9", fontFamily: "Mitr" }}
          flexDirection="column"
        >
          <Label width={"90%"}>
            <Checkbox
              {...register("all_severity")}
              onClick={() => {
                setSeverityText("");
                setSelectedAllSeverity(!selectedAllSeverity);
                setInitialSeverityState();
              }}
            />
            ทั้งหมด
          </Label>
        </Flex>
        {!selectedAllSeverity &&
          _.map(severityData, (severity, i) => (
            <Flex width={1} flexDirection="column" key={i}>
              <Flex
                py={2}
                pl={1}
                width={1}
                justifyContent="center"
                css={{ borderBottom: "1px solid #D0DAE9" }}
                flexDirection="column"
              >
                <Label width={"90%"}>
                  <Checkbox
                    {...register(`severity_${severity.text.toUpperCase()}`)}
                    onClick={(e) =>
                      onClickSelectedSeverity(
                        e.target.checked,
                        severity.text.toUpperCase()
                      )
                    }
                  />
                  {severity.text}
                </Label>
              </Flex>
            </Flex>
          ))}

        <Flex
          py={2}
          width={1}
          justifyContent="center"
          alignItems="center"
          backgroundColor="#fff"
          css={{
            borderBottom: "1px solid #D0DAE9",
            fontFamily: "Mitr",
            position: "sticky",
            bottom: 0,
          }}
        >
          <Button
            width={67}
            backgroundColor="#206ED1"
            color="#fff"
            onClick={onClickApply}
          >
            Apply
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SeverityDropDown;
