import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import "./App.css";
import ScrollToTop from "./Components/Common/ScrollToTop";
import DiagnosisResultPage from "./Pages/DiagnosisResultPage";
import IntroductionLandingPage from "./Pages/IntroductionLandingPage";
import SymptomSelectionPage from "./Pages/SymptomSelection/SymptomSelectionPage";
import SymptomQuestion from "./Pages/SymptomQuestion";
import DiagnosisCompletePage from "./Pages/DiagnosisCompletePage";
import CompleteLandingPage from "./Pages/CompleteLandingPage";
import CompleteContactPage from "./Pages/CompleteContactPage";
import PersonalInfoPage from "./Pages/PersonalInfoPage";
import DashboardLoginPage from "./Pages/DashBoard/DashboardLoginPage";
import MentalDashboardPage from "./Pages/DashBoard/MentalDashboardPage";
import PatientInfoPage from "./Pages/DashBoard/PatientInfoPage";
import UserRatingPage from "./Pages/UserRatingPage";
import UserFeedbackPage from "./Pages/UserFeedbackPage";
import UserFeedbackQuestion from "./Pages/UserFeedbackQuestion";

function App() {
  return (
    <div className="App">
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<IntroductionLandingPage />} />
            <Route
              path="/symptom-selection"
              element={<SymptomSelectionPage />}
            />
            <Route path="/question" element={<SymptomQuestion />} />
            <Route
              path="/diagnosis-complete"
              element={<DiagnosisCompletePage />}
            />
            <Route path="/complete-landing" element={<CompleteLandingPage />} />
            <Route path="/diagnosis-result" element={<DiagnosisResultPage />} />
            <Route path="/personal-info" element={<PersonalInfoPage />} />
            <Route path="/feedback-rating" element={<UserRatingPage />} />
            <Route
              path="/feedback-question"
              element={<UserFeedbackQuestion />}
            />
            <Route path="/feedback-user" element={<UserFeedbackPage />} />
            <Route path="/complete" element={<CompleteContactPage />} />

            {/* Dashboard */}
            <Route path="/dashboard-login" element={<DashboardLoginPage />} />
            <Route path="/mental-dashboard" element={<MentalDashboardPage />} />
            <Route path="/patient-info" element={<PatientInfoPage />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

export default App;
