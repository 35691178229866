import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import _ from "lodash";
import { Flex, Text, Image } from "rebass";
import { Player } from "@lottiefiles/react-lottie-player";

import { authHeader, session } from "../session";
import { apiService } from "../apiService";

import MentalButton from "../Components/MentalButton";
import ConfirmDialog from "../Components/Modal/ConfirmDialog";
import InternetErrorModal from "../Components/Modal/InternetErrorModal";
import agnosLogo from "../Assets/logo/agnos-logo-text.webp";
import doctorImage from "../Assets/doctor-choice-image.svg";

const DiagnosisCompletePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [channel, setChannel] = useState(null);
  const [nonce, setNonce] = useState(null);
  const [recordSession, setRecordSession] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [answers, setAnswers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [processCompleted, setProcessCompleted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [errorModalType, setErrorModalType] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const channel = searchParams.get("channel");
    if (channel) {
      setChannel(channel);
      localStorage.setItem("channel", channel);
    } else {
      setChannel(localStorage.getItem("channel"));
    }
    const nonceVal = searchParams.get("nonce");
    setNonce(nonceVal);

    const userData =
      location.state?.user ?? JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    } else {
      navigate(`/${location.search}`);
    }
    setRecordSession(location.state?.recordSession);
    setStartDateTime(location.state?.startDateTime);
    setAnswers(location.state?.answers);
  }, [location]);

  const onClickContinue = () => {
    setLoading(true);
    submitDiagnosisRecord();

    setTimeout(() => {
      if (processCompleted) {
        setLoading(false);
      }
    }, 3000);
  };

  const submitDiagnosisRecord = async () => {
    const channel = localStorage.getItem("channel");
    let req = {
      record_session: recordSession,
      answers,
      channel,
    };
    const timer = setTimeout(() => {
      setErrorModal(true);
      setErrorModalType("submit");
    }, 5000);
    await apiService
      .post(`/mental/record`, req, authHeader())
      .then((response) => {
        const responseData = response.data;
        const recordId = responseData.record_id;
        localStorage.setItem("diagnosis_result", JSON.stringify(responseData));
        requestDMindRedirect(recordId);
        clearTimeout(timer);
      })
      .catch((err) => {
        if (
          err.message !== "Timeout" &&
          err.message !== "Network request failed"
        ) {
          clearTimeout(timer);
        }
        console.log(err);
        setLoading(false);
        setErrorMessage("submit record failed");
      });
  };

  const requestDMindRedirect = async (recordId) => {
    let req = {
      record_id: recordId,
      nonce: session?.nonce,
    };
    const timer = setTimeout(() => {
      setErrorModal(true);
      setErrorModalType("request");
    }, 5000);
    await apiService
      .post(`/mental/dmind_request`, req, authHeader())
      .then((response) => {
        const responseData = response.data;
        if (responseData.token && responseData.dmind_url) {
          navigateToDMind(responseData.token, responseData.dmind_url);
          setProcessCompleted(true);
        } else {
          setErrorMessage("generate redirect url failed");
        }
        setLoading(false);
        clearTimeout(timer);
      })
      .catch((err) => {
        if (
          err.message !== "Timeout" &&
          err.message !== "Network request failed"
        ) {
          clearTimeout(timer);
        }
        console.log(err);
        setLoading(false);
        setErrorMessage("generate redirect url failed");
      });
  };

  const navigateToDMind = (token, redirectURL) => {
    window.open(redirectURL, "_self");
  };

  return (
    <Flex
      flex={1}
      pb={20}
      justifyContent="center"
      css={{
        background: "#FFF",
      }}
    >
      <Flex
        minHeight="100vh"
        maxWidth={768}
        width={"90%"}
        flexDirection="column"
        alignItems="center"
      >
        <Image mt={36} width={82} src={agnosLogo} alignSelf="flex-start" />
        <Image mt={2} src={doctorImage} />
        <Text
          mt={48}
          fontFamily="Mitr"
          fontSize="1.2rem"
          fontWeight={400}
          textAlign="center"
        >
          เราเข้าใจ {user?.name} มากขึ้นแล้ว
          <br /> เราอยากสัมภาษณ์ {user?.name} เพิ่มอีกหน่อย
        </Text>

        {/* Bottom Bar */}
        <Flex
          width={1}
          height={100}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          css={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 3,
          }}
          pb={50}
        >
          <MentalButton
            width="80%"
            text="ต่อไป"
            backgroundColor="#F2E1C1"
            textColor="#1E1446"
            onClick={onClickContinue}
          />
        </Flex>
      </Flex>

      {/* Success */}
      {loading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          width={1}
          height="100vh"
          sx={{
            position: "absolute",
            background: "rgba(255, 255, 255, 0.6)",
            backdropFilter: "blur(2px)",
            zIndex: 4,
          }}
        >
          <Player
            autoplay={true}
            loop={true}
            src={"https://assets10.lottiefiles.com/packages/lf20_xdgiti07.json"}
            style={{ height: "300px", width: "300px" }}
          />
        </Flex>
      )}

      {/* Error Popup Dialog */}
      <ConfirmDialog
        showConfirmDialog={!_.isEmpty(errorMessage)}
        title="เกิดข้อผิดพลาด"
        message="กรุณาลองใหม่อีกครั้ง"
        confirmButtonTitle="ปิด"
        onConfirm={() => {
          setErrorMessage(null);
          window.close();
        }}
      />

      <InternetErrorModal
        visible={errorModal}
        setVisible={() => setErrorModal(false)}
        buttonFunc={
          errorModalType === "submit"
            ? submitDiagnosisRecord
            : requestDMindRedirect
        }
      />
    </Flex>
  );
};

export default DiagnosisCompletePage;
