import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Flex, Text, Image, Button } from "rebass";
import { Input, Label } from "@rebass/forms";
import { Player } from "@lottiefiles/react-lottie-player";

import { apiService } from "../../apiService";
import { sessionName, setSessionByName } from "../../session";

import dashboardBg from "../../Assets/siriraj.png";
import loginLogo from "../../Assets/login-logo.png";

const DashboardLoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let req = {
      data_source: "MENTAL",
      username: data.username,
      password: data.password,
    };
    setIsLoading(true);
    await apiService
      .post("/doctors/login", req)
      .then((res) => {
        setIsLoading(false);
        const responseData = res.data;
        if (responseData) {
          setSessionByName(sessionName.MENTAL_DOCTOR, responseData);
          navigate("/mental-dashboard?tab=new");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        let errorResponseData = err.response.data;
        if (errorResponseData === "invalid_password") {
          setErrorMessage("รหัสผ่านไม่ถูกต้อง");
          enqueueSnackbar("รหัสผ่านไม่ถูกต้อง", { variant: "error" })
        } else {
          setErrorMessage("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
          enqueueSnackbar("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง", { variant: "error" })
        }
      });
  };

  return (
    <Flex
      as="form"
      width={1}
      minHeight="100vh"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex
        flex={1}
        css={{
          background: `url(${dashboardBg}), radial-gradient(80.71% 80.71% at 91.42% 8.38%, #32AAFF 0%, #1034A6 100%),  no-repeat`,
          backgroundSize: "cover",
          mixBlendMode: "darken",
        }}
      ></Flex>
      <Flex
        flex={1}
        backgroundColor="#DEF6FF"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          flexDirection="column"
          minHeight={490}
          width="90%"
          p={36}
          css={{
            background: "#fff",
            borderRadius: ".5rem",
            boxShadow: "0xp 0px 0px 2px rgba(197, 197, 197, 0.25)",
          }}
        >
          <Text fontFamily="SukhumvitSet-Medium" color="#1034A6">
            <h1
              style={{
                fontSize: "2rem",
              }}
            >
              Login{" "}
            </h1>
            <br />
            <br />
            <h3
              style={{
                fontSize: "1.2rem",
              }}
            >
              เข้าสู่ระบบ
            </h3>
          </Text>
          <Label
            mt={4}
            minHeight={54}
            fontFamily="SukhumvitSet-Medium"
            css={{
              boxShadow: "0px 4px 8px 4px rgba(216, 216, 216, 0.41)",
              borderRadius: "12px",
            }}
          >
            <Input
              placeholder="Username"
              type="username"
              {...register("username", { required: true })}
              css={{
                border: "none",
              }}
            />
          </Label>
          <Label
            mt={3}
            minHeight={54}
            fontFamily="SukhumvitSet-Medium"
            css={{
              boxShadow: "0px 4px 8px 4px rgba(216, 216, 216, 0.41)",
              borderRadius: "12px",
            }}
          >
            <Input
              placeholder="Password"
              type="password"
              {...register("password", { required: true })}
              css={{
                border: "none",
              }}
            />
          </Label>
          <Text
            my={1}
            fontFamily="Mitr"
            textAlign="start"
            letterSpacing="0.5px"
            color="#D21C1C"
            css={{
              visibility: errorMessage ? "visible" : "hidden",
            }}
          >
            {errorMessage}
          </Text>
          <Button
            mt={48}
            type="submit"
            minHeight={41}
            width="30%"
            alignSelf="center"
            fontFamily="SukhumvitSet-Medium"
            backgroundColor="#206ED1"
            color="#fff"
          >
            Login
          </Button>
        </Flex>
        <Flex
          sx={{ position: "absolute", bottom: 40, right: 40 }}
          alignItems="center"
        >
          <Text
            fontFamily="SukhumvitSet-Medium"
            color="#206ED1"
            fontSize={16}
            mr="0.5em"
          >
            Powered by
          </Text>
          <Image src={loginLogo} width={40}></Image>
        </Flex>
      </Flex>

      {/* Loading */}
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          width={1}
          height="100vh"
          sx={{
            position: "absolute",
            background: "rgba(255, 255, 255, 0.6)",
            backdropFilter: "blur(2px)",
            zIndex: 4,
          }}
        >
          <Player
            autoplay={true}
            loop={true}
            src={"https://assets9.lottiefiles.com/packages/lf20_earcnwm3.json"}
            style={{ height: "200px", width: "200px" }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default DashboardLoginPage;
