import React from "react";
import { Flex, Text, Image } from "rebass";

const TabbarBox = ({ text, onClick, onSelected }) => {
  return (
    <Flex
      flex={1}
      mr={0.8}
      minHeight={64}
      justifyContent="center"
      alignItems="center"
      backgroundColor={onSelected ? "#fff" : "rgba(32, 110, 209, 0.2)"}
      onClick={onClick}
      css={{
        boxShadow: onSelected
          ? "0px -3px 2px 2px rgba(167,167,167,0.25)"
          : "none",
        borderRadius: "9px 9px 0px 0px",
        position: "relative",
        color: "#1034A6",
        fontFamily: "'SukhumvitSet-Medium'",
        fontSize: "1.1rem",
        fontWeight: 700,
        borderBottom: onSelected && "2px solid #fff",
        marginBottom: onSelected && "-2px",
        zIndex: onSelected && 3,
        userSelect: "none",
        cursor: "pointer",
        transition: "1s",
      }}
    >
      <Flex
        display={onSelected ? "flex" : "none! important"}
        backgroundColor="#1034A6"
        width={1}
        minHeight={10}
        css={{
          position: "absolute",
          top: 0,
          borderRadius: "9px 9px 0px 0px",
          fontFamily: "Sukhumvit-Set",
          color: "#1034A6",
        }}
      />
      {text}
    </Flex>
  );
};

export default TabbarBox;
