import React from "react";
import { Flex } from "rebass";
import { format, formatDistanceStrict } from "date-fns";
import th from "date-fns/locale/th";
import SeverityLevelBox from "./SeverityLevelBox";
import { APP_ENV } from "../../../config";

const severeLevel = {
  SEVERE: {
    text: "Severe",
    color: "#FE274A",
    bgColor: "#FEEBEB",
  },
  MODERATE: {
    text: "Moderate",
    color: "#DF8900",
    bgColor: "#FFF4D9",
  },
  MILD: {
    text: "Mild",
    color: "#10675A",
    bgColor: "#CAFFF3",
  },
};

const TableContent = ({ record, onClickRecord }) => {
  const formatDate = (pubDate) => {
    const dateStr = new Date(pubDate).toLocaleDateString("th-TH", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const timeStr = format(new Date(pubDate), " (HH:mm)");
    return dateStr + timeStr;
  };

  console.log(record);

  return (
    <Flex
      py={2}
      width={1}
      minHeight={90}
      alignItems="center"
      css={{
        borderBottom: "1px solid #D0DAE9",
        fontFamily: "SukhumvitSet-Medium",
        fontWeight: 600,
      }}
      onClick={() => onClickRecord(record?.record_id)}
    >
      <Flex flex={1} justifyContent="center" color="#7182A6">
        {record?.record_id ? record?.record_id : "-"}
      </Flex>
      <Flex
        flex={1}
        alignItems="center"
        flexDirection="column"
        css={{
          whiteSpace: "pre-line",
        }}
      >
        <span>{record?.phone_number ? record?.phone_number : "-"}</span>
        <span
          style={{
            color: "#7182A6",
            display: record?.first_name ? "unset" : "none",
          }}
        >
          {record?.first_name
            ? `${record?.first_name} ${record?.last_name}`
            : "-"}
        </span>
      </Flex>
      <Flex flex={1} justifyContent="center" color="#7182A6">
        {record?.last_contact_date
          ? formatDistanceStrict(
              new Date(record?.last_contact_date),
              new Date(),
              {
                locale: th,
              }
            ) + "ที่แล้ว"
          : "-"}
      </Flex>
      <Flex
        flex={1}
        flexDirection="column"
        alignItems="center"
        css={{
          whiteSpace: "pre-line",
        }}
      >
        <span>{record?.province_area ? record?.province_area : "-"}</span>
        <span
          style={{
            color: "#7182A6",
          }}
        >
          {record?.province ? record?.province : "-"}
        </span>
      </Flex>
      <Flex
        display={APP_ENV === "uat" ? "flex" : "none !important"}
        flex={1}
        justifyContent="center"
      >
        {`${record?.hamd_score}/${record?.eightq_score}`}
      </Flex>
      <Flex flex={1} justifyContent="center">
        <SeverityLevelBox record={record} width="70%" />
      </Flex>
      <Flex flex={1} justifyContent="center" color="#7182A6">
        {record?.pub_date ? formatDate(record?.pub_date) : "-"}
      </Flex>
      <Flex flex={1} justifyContent="center" color="#7182A6">
        {record?.channel ? record?.channel : "-"}
      </Flex>
    </Flex>
  );
};

export default TableContent;
