import _ from "lodash";
import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Flex, Text } from "rebass";

import { choiceType } from ".";
import SingleChoice from "./Choice/SingleChoice";
import "../../Styles/questionTransition.css";

const SingleQuestion = ({
  question,
  user,
  nextState,
  transition,
  setTransition,
  onAnswer,
}) => {
  const [questionTitle, setQuestionTitle] = useState("");

  useEffect(() => {
    if (!question) return;
    setTransition(true);

    const title = _.replace(
      question?.question,
      new RegExp("NAME", "g"),
      user?.name
    );
    setQuestionTitle(title);
  }, [question]);

  const onSelectAnswer = (choice) => {
    onAnswer([choice]);
  };

  return (
    <CSSTransition classNames={nextState} in={transition} timeout={200}>
      <Flex
        flexDirection="column"
        mt={22}
        minHeight={240}
        css={{
          background: "#FFF",
          border: "1px solid #EDEFF2",
          borderRadius: "16px",
          zIndex: 1,
        }}
        px={20}
        py={32}
      >
        {/* Title */}
        <Text fontFamily="Mitr" textAlign="center" color="#1E1446" mb={12}>
          <h4 style={{ fontSize: "1.3rem", fontWeight: 500 }}>
            {questionTitle}
          </h4>
        </Text>

        {/* Choices */}
        {_.map(question?.choices, (choice) => {
          switch (question.choice_type) {
            case choiceType.RADIO:
              return (
                <SingleChoice
                  key={`choice-${choice.choice_id}`}
                  choice={choice}
                  onSelect={() => onSelectAnswer(choice)}
                />
              );
            default:
              return null;
          }
        })}
      </Flex>
    </CSSTransition>
  );
};

export default SingleQuestion;
