import { format } from "date-fns";
import React from "react";
import { Flex, Text } from "rebass";
import SeverityLevelBox from "./SeverityLevelBox";

const PatientInfoBox = ({ record }) => {
  const formatDate = (pubDate) => {
    const dateStr = new Date(pubDate).toLocaleDateString("th-TH", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const timeStr = format(new Date(pubDate), " (HH:mm)");
    return dateStr + timeStr;
  };
  return (
    <Flex
      mt={3}
      width={1}
      css={{
        gap: "36px",
        flexWrap: "wrap"
      }}
    >
      {/* left box */}
      <Flex
        flex={1}
        minHeight={380}
        minWidth={350}
        flexDirection="column"
        css={{
          background: "#fff",
          boxShadow: "0px 0px 0px 2px rgba(197, 197, 197, 0.25)",
          borderRadius: "7px",
          padding: "1.6rem 2rem",
        }}
      >
        <Text color="#788295" fontSize="1.1rem" fontWeight={600}>
          Case ID:
          <p
            style={{
              color: "#38454D",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {record?.record_id}
          </p>
        </Text>
        <Text mt={4} color="#788295" fontSize="1.1rem" fontWeight={600}>
          ชื่อ:
          <p
            style={{
              color: "#38454D",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {record?.first_name
              ? `${record?.first_name} ${record?.last_name}`
              : "-"}
          </p>
        </Text>
        <Text mt={4} color="#788295" fontSize="1.1rem" fontWeight={600}>
          ชื่อ Facebook:
          <p
            style={{
              color: "#38454D",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {record?.facebook_display_name
              ? `${record?.facebook_display_name}`
              : "-"}
          </p>
        </Text>
        <Text mt={4} color="#788295" fontSize="1.1rem" fontWeight={600}>
          เบอร์โทรศัพท์:
          <p
            style={{
              color: "#38454D",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {record?.phone_number
              ? record?.phone_number.replace(
                  /^(?=[0-9]{3})([0-9]{5})([0-9]{8})([0-9])$/,
                  "$1-$2-$3"
                )
              : "-"}
          </p>
        </Text>
        
        <Text mt={4} mb={2} color="#788295" fontSize="1.1rem" fontWeight={600}>
          ระดับความรุนแรง:
        </Text>
        <SeverityLevelBox record={record} />

      </Flex>

      {/* right box */}
      <Flex
        flex={1}
        flexDirection="column"
        minHeight={380}
        minWidth={350}
        css={{
          background: "#fff",
          boxShadow: "0px 0px 0px 2px rgba(197, 197, 197, 0.25)",
          borderRadius: "7px",
          padding: "1.6rem 2rem",
        }}
      >
        <Text color="#788295" fontSize="1.1rem" fontWeight={600}>
          เขตพื้นที่:
          <p
            style={{
              color: "#38454D",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {record?.province_area ? `${record?.province_area}, ${record?.province}` : ""}
          </p>
        </Text>
        <Text mt={4} color="#788295" fontSize="1.1rem" fontWeight={600}>
          เลขประจำตัวประชาชน:
          <p
            style={{
              color: "#38454D",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {record?.citizen_id ? record?.citizen_id : "-"}
          </p>
        </Text>
        <Text mt={4} color="#788295" fontSize="1.1rem" fontWeight={600}>
          ที่อยู่:
          <p
            style={{
              color: "#38454D",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {record?.address ? record?.address : "-"}
          </p>
        </Text>
        <Text mt={4} color="#788295" fontSize="1.1rem" fontWeight={600}>
          Visit date:
          <p
            style={{
              color: "#38454D",
              fontSize: "1.3rem",
              fontWeight: 600,
            }}
          >
            {record?.pub_date ? formatDate(record?.pub_date) : "-"}
          </p>
        </Text>
      </Flex>
    </Flex>
  );
};

export default PatientInfoBox;
