import React, { useEffect, useState } from "react";
import { Flex, Button, Text, Box, Image } from "rebass";
import { Checkbox, Label } from "@rebass/forms";

import see_more_icon from "../../../Assets/see-more-icon.svg";
import SeeMoreModal from "../SeeMore/SeeMoreModal";

import "../../../Styles/CustomCheckbox.css";

const MultipleMainChoice = ({
  question,
  isNoAll,
  seeMoreExists,
  disabled,
  onSelect,
  onSelectNoAll,
}) => {
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [selected, setSelected] = useState(false);

  const onSelectQuestion = () => {
    setSelected(!selected);

    if (isNoAll) {
      onSelectNoAll();
    } else {
      onSelect();
    }
  };

  return (
    <Flex width={1} justifyContent="space-between">
      {/* Choice */}
      <Button
        display="flex"
        flex={2}
        alignItems="center"
        justifyContent="center"
        css={{
          border: disabled
            ? "1px solid #CACACA"
            : selected
            ? "1px solid #F2E1C1"
            : "1px solid #1E1446",
          borderRadius: "26px",
          background: selected && !disabled ? "#F2E1C1" : "#FFF",
          transform: selected && !disabled ? "scale(1.05)" : "none",
        }}
        width={1}
        my={12}
      >
        <Label
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Flex textAlign="start" alignItems="flex-start">
            <Checkbox
              // id={choice.choice_key}
              // name={choice.choice_key}
              // ref={register}
              css={{
                outline: "none",
                boxSizing: "border-box",
                borderRadius: "5px",
                color: "#CACACA",
                backgroundColor: "#CACACA",
              }}
              width={24}
              height={24}
              disabled={disabled}
              onClick={onSelectQuestion}
            />
            <Flex mt="2px" ml="8px" width={1} height="100%">
              <Text
                fontFamily="Mitr"
                textAlign="left"
                color={disabled ? "#CACACA" : "#1E1446"}
              >
                <span style={{ fontSize: "1.2rem", fontWeight: 300 }}>
                  {question.question}
                </span>
              </Text>
            </Flex>
          </Flex>
        </Label>
      </Button>

      {/* See More Button */}
      <Flex
        display={seeMoreExists ? "flex" : "none"}
        ml={seeMoreExists ? "16px" : "0px"}
        width={seeMoreExists ? 24 : 0}
        alignItems="center"
        justifyContent="center"
      >
        {(question.see_more || question.see_more_image) && (
          <Box
            width={24}
            height={24}
            css={{
              "&:hover": {
                transform: "scale(0.9)",
              },
            }}
            onClick={() => {
              setShowSeeMore(true);
            }}
          >
            <Image src={see_more_icon} width={24} height={24} />
          </Box>
        )}
      </Flex>

      {/* See More Modal */}
      <SeeMoreModal
        open={showSeeMore}
        question={question}
        onClose={() => setShowSeeMore(false)}
      />
    </Flex>
  );
};

export default MultipleMainChoice;
