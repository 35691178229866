import React, { useState } from "react";
import { Flex, Button, Text } from "rebass";
import { Checkbox, Label } from "@rebass/forms";

const MultipleChoice = ({ choice, isNoAll, disabled, onSelect, onSelectNoAll }) => {
  const [selected, setSelected] = useState(false);

  const onSelectChoice = () => {
    setSelected(!selected);

    if (isNoAll) {
      onSelectNoAll();
    } else {
      onSelect();
    }
  };

  return (
    <Button
      display="flex"
      flex={2}
      alignItems="center"
      justifyContent="center"
      css={{
        border: disabled
          ? "1px solid #CACACA"
          : selected
          ? "1px solid #F2E1C1"
          : "1px solid #1E1446",
        borderRadius: "26px",
        background: selected && !disabled ? "#F2E1C1" : "#FFF",
        transform: selected && !disabled ? "scale(1.05)" : "none",
      }}
      width={1}
      my={12}
    >
      <Label
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Flex textAlign="start" alignItems="flex-start">
          <Checkbox
            css={{
              outline: "none",
              boxSizing: "border-box",
              borderRadius: "5px",
              color: "#CACACA",
              backgroundColor: "#CACACA",
            }}
            width={24}
            height={24}
            disabled={disabled}
            onClick={onSelectChoice}
          />
          <Flex ml="8px" width={1} height="100%">
            <Text
              fontFamily="Mitr"
              textAlign="left"
              color={disabled ? "#CACACA" : "#1E1446"}
            >
              <span style={{ fontSize: "1.2rem", fontWeight: 300 }}>
                {choice?.choice_text}
              </span>
            </Text>
          </Flex>
        </Flex>
      </Label>
    </Button>
  );
};

export default MultipleChoice;
