import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Flex, Text, Image } from "rebass";
import { Textarea } from "@rebass/forms";

import { apiService } from "../apiService";
import { authHeader, checkSession, checkSessionByName, sessionName } from "../session";
import { hamdSeverity } from "../models/severity";

import NavBar from "../Components/Navbar";
import MentalButton from "../Components/MentalButton";
import SuccessModal from "../Components/Modal/SuccessModal";
import editIcon from "../Assets/edit-icon.svg";
import feedbackBg from "../Assets/feedback-bg.svg";

const UserFeedbackPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [feedback, setFeedback] = useState(null);
  const [severity, setSeverity] = useState(hamdSeverity.NORMAL);
  const [consentAccepted, setConsentAccepted] = useState(false);
  const [diagnosisResult, setDiagnosisResult] = useState(null);

  const [currentInput, setCurrentInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // const checkUserSession = async () => {
    //   await checkSessionByName(sessionName.MENTAL, navigate, "/")
    // }
    // checkUserSession();

    const result = JSON.parse(localStorage.getItem("diagnosis_result"));
    if (!result) {
      navigate("/");
      return;
    }
    setDiagnosisResult(result);

    setFeedback(location.state?.feedback);
    setSeverity(location.state?.severity ?? hamdSeverity.NORMAL);
    setConsentAccepted(location.state?.consent ?? false);
  }, [location]);

  const onClickSendFeedback = async (skip) => {
    if (!diagnosisResult) return;
    let req = {
      ...feedback,
      suggestion: !skip ? currentInput : "",
    };

    console.log(authHeader())
    await apiService
      .post(
        `/mental/mental_records/${diagnosisResult.record_id}/feedback`,
        req,
        authHeader()
      )
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === "success") {
          setIsModalOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickCloseModal = () => {
    setIsModalOpen(false);
    navigate(`/complete`, { state: { severity, consent: consentAccepted } });
  };

  return (
    <Flex
      minHeight="100vh"
      width={1}
      flexDirection="column"
      alignItems="center"
      css={{
        background: `#FDFEF9 url(${feedbackBg}) no-repeat bottom left`,
      }}
    >
      <NavBar
        title="แสดงความคิดเห็น"
        backOnly
        fontSize="1.4rem"
        fontWeight={400}
      />
      <Flex
        pb={20}
        minHeight={"80vh"}
        flexDirection="column"
        alignItems="center"
        maxWidth={768}
        width="90%"
        fontFamily="Mitr"
        textAlign="center"
      >
        <Text mt={3} display="flex" alignItems="center">
          <h3
            style={{
              fontSize: "1.3rem",
              marginBottom: 0,
            }}
          >
            อยากบอกอะไรกับเราเพิ่มเติมไหม
          </h3>
          <Image ml={2} src={editIcon} />
        </Text>

        <Textarea
          mt={4}
          minHeight="50vh"
          maxWidth={600}
          p={4}
          placeholder="พิมพ์ข้อเสนอแนะ..."
          css={{
            border: "1px solid #1E1446",
            borderRadius: "34px",
            fontFamily: "Mitr",
            ":focus": {
              outline: "none",
            },
          }}
          onChange={(e) => setCurrentInput(e.target.value)}
        />

        {/* Button */}
        <MentalButton
          mt={48}
          width={1}
          text="ส่งคำแนะนำ"
          textColor="#1E1446"
          backgroundColor="#F2E1C1"
          disabled={_.isEmpty(currentInput)}
          onClick={() => onClickSendFeedback(false)}
        />
        <MentalButton
          mt={3}
          width={1}
          text="ข้าม"
          textColor="#1E1446"
          backgroundColor="#fff"
          border="2px solid #F2E1C1"
          onClick={() => onClickSendFeedback(true)}
        />
        <SuccessModal open={isModalOpen} onClose={onClickCloseModal} />
      </Flex>
    </Flex>
  );
};

export default UserFeedbackPage;
