import React from "react";
import _ from "lodash";
import { format } from "date-fns";
import { Flex, Text } from "rebass";
import { Checkbox, Label } from "@rebass/forms";
import "../../../Styles/CustomCheckbox.css";

const PatientContactCheckBox = ({ register, watch, notAcceptedList }) => {
  const formatDate = (pubDate) => {
    const dateStr = new Date(pubDate).toLocaleDateString("th-TH", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const timeStr = format(new Date(pubDate), ", HH:mm");
    return dateStr + timeStr;
  };

  return (
    <>
      <Text mt={36} color="#003A66" fontWeight={700} fontSize="1.5rem">
        บันทึกกรณีติดต่อไม่สำเร็จ
      </Text>
      {/* Not Accepted */}
      <Flex>
        {notAcceptedList && (
          <Flex mt={4} alignItems="flex-start">
            <Label
              fontSize="1.4rem"
              color={!watch("invalid_phone_number") ? "#003A66" : "#979797"}
              fontWeight={600}
              alignItems="center"
            >
              <Checkbox
                name="isNotAcceptCall"
                {...register("is_not_accept_call", { required: false })}
                mr={2}
                disabled={watch("invalid_phone_number")}
                color="#075FD2"
                backgroundColor={watch("invalid_phone_number") ? "#979797" : "transparent"}
              />
              ไม่รับสาย
            </Label>
            <Flex flexDirection="column" minWidth={280} mt="6px" ml="14px">
              {_.map(notAcceptedList, (log, i) => (
                <Text fontSize="1rem" color="#38454D" fontWeight={500}>
                  {`ไม่รับสายครั้งที่ ${i + 1} - ${formatDate(log.pub_date)}`}
                </Text>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
      {watch("is_not_accept_call") && (
        <Flex ml={28}>
          <Text color="#D22626" fontFamily="SukhumvitSet-Medium" fontSize={16}>
            *
          </Text>
          <Text
            color="#979797"
            fontFamily="SukhumvitSet-Medium"
            fontSize={16}
            ml="8px"
          >
            ข้อมูลส่วนนี้จะถูกบันทึกลงในบันทึกเพิ่มเติม หากไม่รับสายหลายครั้ง
            กรุณาเปลี่ยนเป็นบันทึกเบอร์โทรศัพท์ไม่ถูกต้องในครั้งถัดไป
          </Text>
        </Flex>
      )}

      {/* Invalid Phone Number */}
      <Label
        mt={4}
        fontSize="1.4rem"
        color={!watch("is_not_accept_call") ? "#003A66" : "#979797" }
        fontWeight={600}
        alignItems="center"
      >
        <Checkbox
          name="invalidPhoneNumber"
          {...register("invalid_phone_number", { required: false })}
          mr={2}
          color="#075FD2"
          backgroundColor={watch("is_not_accept_call") ? "#979797" : "transparent"}
          disabled={watch("is_not_accept_call")}
        />
        เบอร์โทรศัพท์ไม่ถูกต้อง
      </Label>
      {watch("invalid_phone_number") && (
        <Flex ml={28}>
          <Text color="#D22626" fontFamily="SukhumvitSet-Medium" fontSize={16}>
            *
          </Text>
          <Text
            color="#979797"
            fontFamily="SukhumvitSet-Medium"
            fontSize={16}
            ml="8px"
          >
            หลังจากบันทึกว่าเบอร์โทรศัพท์ไม่ถูกต้อง
            ข้อมูลของบุคคลนี้จะถูกเปลี่ยนสถานะและย้ายไปที่หน้า done
          </Text>
        </Flex>
      )}
    </>
  );
};

export default PatientContactCheckBox;
