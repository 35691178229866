import React from "react";
import { Flex, Text } from "rebass";
import { Input, Label, Radio } from "@rebass/forms";

const PatientRiskScore = ({ register, disabled, errors }) => {
  return (
    <Flex
      mt={3}
      width={1}
      css={{
        gap: "36px",
        fontFamily: "SukhumvitSet-Medium",
      }}
      color={disabled ? "#979797" : "#003A66"}
      fontWeight={600}
    >
      {/* Eight Q Score */}
      <Flex
        flex={1}
        minHeight={380}
        minWidth={450}
        flexDirection="column"
        css={{
          padding: "1.6rem 2rem",
        }}
        textAlign="start"
      >
        <Label alignItems="center" fontSize="1.4rem" fontWeight={600}>
          คะแนน 8Q
          <Input
            type="number"
            name="eightqScore"
            {...register("eightq_score", {
              // required: !disabled ? "กรุณาระบุคะแนน" : false,
              disabled: disabled,
              min: {
                value: 0,
                message: "คะแนนไม่ถูกต้อง",
              },
              max: {
                value: 52,
                message: "คะแนนไม่ถูกต้อง",
              },
            })}
            width={119}
            height={56}
            ml={3}
            textAlign="center"
            placeholder="0-52"
            css={{
              border: errors.eightq_score ? "1px solid #FE274A" : "none",
              background: "#fff",
              boxShadow: "0px 4px 17px 4px #D7E7F2",
              borderRadius: "7px",
              ":focus": {
                outline: "none",
              },
              ":placeholder": {
                color: "#C5C4C4",
              },
            }}
          />
          {errors.eightq_score && (
            <p
              style={{
                color: "#FE274A",
                fontSize: "1rem",
                fontWeight: 700,
                marginLeft: "12px",
              }}
            >
              {errors.eightq_score.message}
            </p>
          )}
        </Label>
        <Flex mt={36} alignItems="center">
          <Text fontSize="1.4rem" fontWeight={600}>
            *ระดับความเสี่ยงฆ่าตัวตาย
          </Text>
          {errors.eightq_severity_level && (
            <p
              style={{
                color: "#FE274A",
                fontSize: "1rem",
                fontWeight: 700,
                marginLeft: "12px",
              }}
            >
              {errors.eightq_severity_level.message}
            </p>
          )}
        </Flex>

        <Label mt={3} alignItems="center" fontSize="1.2rem">
          <Radio
            {...register("eightq_severity_level", {
              required: !disabled ? "กรุณาเลือกความเสี่ยง" : false,
              disabled: disabled,
            })}
            value={"NORMAL"}
            color={disabled ? "#979797" : "#0A7AFF"}
          />
          No suicidal tendency
        </Label>
        <Label mt={3} alignItems="center" fontSize="1.2rem">
          <Radio
            {...register("eightq_severity_level", {
              required: !disabled ? "กรุณาเลือกความเสี่ยง" : false,
              disabled: disabled,
            })}
            value={"MILD"}
            color={disabled ? "#979797" : "#0A7AFF"}
          />
          Mild
        </Label>
        <Label mt={3} alignItems="center" fontSize="1.2rem">
          <Radio
            {...register("eightq_severity_level", {
              required: !disabled ? "กรุณาเลือกความเสี่ยง" : false,
              disabled: disabled,
            })}
            value={"MODERATE"}
            color={disabled ? "#979797" : "#0A7AFF"}
          />
          Moderate
        </Label>
        <Label mt={3} alignItems="center" fontSize="1.2rem">
          <Radio
            {...register("eightq_severity_level", {
              required: !disabled ? "กรุณาเลือกความเสี่ยง" : false,
              disabled: disabled,
            })}
            value={"SEVERE"}
            color={disabled ? "#979797" : "#0A7AFF"}
          />
          Severe
        </Label>
      </Flex>

      {/* Nine Q Score */}
      <Flex
        flex={1}
        minHeight={380}
        minWidth={450}
        flexDirection="column"
        css={{
          padding: "1.6rem 2rem",
        }}
        textAlign="start"
      >
        <Label alignItems="center" fontSize="1.4rem" fontWeight={600}>
          คะแนน 9Q
          <Input
            type="number"
            name="nineqScore"
            {...register("nineq_score", {
              // required: !disabled ? "กรุณาระบุคะแนน" : false,
              disabled: disabled,
              min: {
                value: 0,
                message: "คะแนนไม่ถูกต้อง",
              },
              max: {
                value: 30,
                message: "คะแนนไม่ถูกต้อง",
              },
            })}
            width={119}
            height={56}
            ml={3}
            textAlign="center"
            placeholder="0-30"
            css={{
              border: errors.nineq_score ? "1px solid #FE274A" : "none",
              background: "#fff",
              boxShadow: "0px 4px 17px 4px #D7E7F2",
              borderRadius: "7px",
              ":focus": {
                outline: "none",
              },
              ":placeholder": {
                color: "#C5C4C4",
              },
            }}
          />
          {errors.nineq_score && (
            <p
              style={{
                color: "#FE274A",
                fontSize: "1rem",
                fontWeight: 700,
                marginLeft: "12px",
              }}
            >
              {errors.nineq_score.message}
            </p>
          )}
        </Label>
        <Flex mt={36} alignItems="center">
          <Text fontSize="1.4rem" fontWeight={600}>
            *ระดับความรุนแรงซึมเศร้า
          </Text>
          {errors.nineq_severity_level && (
            <p
              style={{
                color: "#FE274A",
                fontSize: "1rem",
                fontWeight: 700,
                marginLeft: "12px",
              }}
            >
              {errors.nineq_severity_level.message}
            </p>
          )}
        </Flex>

        <Label mt={3} alignItems="center" fontSize="1.2rem">
          <Radio
            {...register("nineq_severity_level", {
              required: !disabled ? "กรุณาเลือกความเสี่ยง" : false,
              disabled: disabled,
            })}
            value={"NORMAL"}
            color={disabled ? "#979797" : "#0A7AFF"}
          />
          Non MDD
        </Label>
        <Label mt={3} alignItems="center" fontSize="1.2rem">
          <Radio
            {...register("nineq_severity_level", {
              required: !disabled ? "กรุณาเลือกความเสี่ยง" : false,
              disabled: disabled,
            })}
            value={"MILD"}
            color={disabled ? "#979797" : "#0A7AFF"}
          />
          Mild
        </Label>
        <Label mt={3} alignItems="center" fontSize="1.2rem">
          <Radio
            {...register("nineq_severity_level", {
              required: !disabled ? "กรุณาเลือกความเสี่ยง" : false,
              disabled: disabled,
            })}
            value={"MODERATE"}
            color={disabled ? "#979797" : "#0A7AFF"}
          />
          Moderate
        </Label>
        <Label mt={3} alignItems="center" fontSize="1.2rem">
          <Radio
            {...register("nineq_severity_level", {
              required: !disabled ? "กรุณาเลือกความเสี่ยง" : false,
              disabled: disabled,
            })}
            value={"SEVERE"}
            color={disabled ? "#979797" : "#0A7AFF"}
          />
          Severe
        </Label>
      </Flex>
    </Flex>
  );
};

export default PatientRiskScore;
