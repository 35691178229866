import React from "react";
import { Flex } from "rebass";
import { APP_ENV } from "../../../config";

const HeaderTextTable = () => {
  return (
    <Flex
      mt={36}
      width={1}
      minWidth={800}
      fontFamily="SukhumvitSet-Medium"
      fontSize="1rem"
      fontWeight={700}
    >
      <Flex flex={1} justifyContent="center">
        Case ID
      </Flex>
      <Flex flex={1} justifyContent="center">
        เบอร์โทรศัพท์/ชื่อ
      </Flex>
      <Flex flex={1} justifyContent="center">
        ติดต่อล่าสุด
      </Flex>
      <Flex flex={1} justifyContent="center">
        พื้นที่
      </Flex>
      <Flex
        display={APP_ENV === "uat" ? "flex" : "none !important"}
        flex={1}
        justifyContent="center"
      >
        HamD/8Q
      </Flex>
      <Flex flex={1} justifyContent="center">
        ระดับความรุนแรง
      </Flex>
      <Flex flex={1} justifyContent="center">
        วันที่
      </Flex>
      <Flex flex={1} justifyContent="center">
        ช่องทาง
      </Flex>
    </Flex>
  );
};

export default HeaderTextTable;
