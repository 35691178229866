import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";
import { Flex, Text } from "rebass";

import MultipleMainChoice from "./Choice/MultipleMainChoice";
import MentalButton from "../MentalButton";
import "../../Styles/questionTransition.css";

const MultipleMainQuestion = ({
  questionData,
  questions,
  user,
  transition,
  setTransition,
  nextState,
  onAnswer,
}) => {
  const [questionTitle, setQuestionTitle] = useState("");
  const [currentMultipleChoice, setCurrentMultipleChoice] = useState({});
  const [noAllSelected, setNoAllSelected] = useState(false);
  const [seeMoreExist, setSeeMoreExist] = useState(true);
  const [choiceSelected, setChoiceSelected] = useState(false);

  useEffect(() => {
    if (!questions) return;
    setTransition(true);

    const title = _.replace(
      "ขอบคุณ NAME มากเลยที่เล่าให้เราฟัง มีอะไรอีกไหมที่อยากเล่า",
      new RegExp("NAME", "g"),
      user?.name
    );
    setQuestionTitle(title);
  }, [questions]);

  useEffect(() => {
    let seeMoreDidExist = false;
    let newCurrentMultipleChoice = currentMultipleChoice;
    _.forEach(questions, (question) => {
      // init current multiple choice questions
      newCurrentMultipleChoice = {
        ...newCurrentMultipleChoice,
        [`${question.question}`]: _.find(question.choices, {
          choice_word: "NO",
        }),
      };

      // check if see more exists
      if (question.see_more || question.see_more_image) {
        seeMoreDidExist = true;
      }
    });

    setCurrentMultipleChoice(newCurrentMultipleChoice);
    setSeeMoreExist(seeMoreDidExist);
  }, [questions]);

  useEffect(() => {
    checkChoiceSelection();
  }, [currentMultipleChoice]);

  const onSelectQuestion = (question) => {
    if (
      currentMultipleChoice[question.question] &&
      currentMultipleChoice[question.question].choice_id ===
        _.find(question.choices, { choice_word: "YES" }).choice_id
    ) {
      setCurrentMultipleChoice({
        ...currentMultipleChoice,
        [`${question.question}`]: _.find(question.choices, {
          choice_word: "NO",
        }),
      });
    } else {
      setCurrentMultipleChoice({
        ...currentMultipleChoice,
        [`${question.question}`]: _.find(question.choices, {
          choice_word: "YES",
        }),
      });
    }
  };

  const checkChoiceSelection = () => {
    setChoiceSelected(false);
    _.forEach(currentMultipleChoice, (choice) => {
      if (choice?.choice_word === "YES") {
        setChoiceSelected(true);
        return;
      }
    });
  };

  const onSelectNoAll = () => {
    setNoAllSelected(!noAllSelected);
  };

  const onAnswerMultipleMain = () => {
    if (noAllSelected) {
      onAnswer([{ choice_id: -1 }]);
      return;
    }

    const selectedChoices = _.map(questions, (c) => {
      if (
        currentMultipleChoice[c.question] &&
        currentMultipleChoice[c.question].choice_id ===
          _.find(c.choices, {
            choice_word: "YES",
          }).choice_id
      ) {
        return currentMultipleChoice[c.question];
      }
    });

    // filter undefined values
    const filteredChoices = _.filter(
      selectedChoices,
      (choice) => !_.isEmpty(choice)
    );

    // filter unique choice ids
    const uniqueChoices = _.uniqBy(
      filteredChoices,
      (choice) => choice.choice_id
    );

    onAnswer(uniqueChoices);
  };

  return (
    <CSSTransition classNames={nextState} in={transition} timeout={200}>
      <Flex
        flexDirection="column"
        mt={22}
        minHeight={320}
        css={{
          background: "#FFF",
          border: "1px solid #EDEFF2",
          borderRadius: "16px",
          zIndex: 1,
        }}
        px={20}
        py={32}
      >
        {/* Title */}
        {questionData?.phase === 2 && (
          <Text fontFamily="Mitr" textAlign="center" color="#1E1446" mb={12}>
            <h4 style={{ fontSize: "1.3rem", fontWeight: 500 }}>
              {questionTitle}
            </h4>
            <h5 style={{ fontSize: "1rem", fontWeight: 300 }}>
              “จริงๆแล้วฉันก็รู้สึกแบบนี้นะ...”
            </h5>
          </Text>
        )}

        {_.map(questions, (question) => {
          return (
            <MultipleMainChoice
              key={`multiple-${question.question_key}-${question.question}`}
              question={question}
              seeMoreExists={seeMoreExist}
              disabled={noAllSelected}
              onSelect={() => onSelectQuestion(question)}
            />
          );
        })}
        {questions && (
          <MultipleMainChoice
            isNoAll
            seeMoreExists={seeMoreExist}
            question={{ question: "ไม่มีอาการที่กล่าวมา" }}
            onSelectNoAll={onSelectNoAll}
          />
        )}

        {/* Bottom Bar */}
        <Flex
          width={1}
          height={100}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          css={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 3,
            background:
              " linear-gradient(175deg, rgba(196, 196, 196, 0) 20.72%, #DFD1B5 94.83%)",
          }}
        >
          <MentalButton
            width="80%"
            text="ต่อไป"
            backgroundColor="#F2E1C1"
            textColor="#1E1446"
            disabled={!choiceSelected && !noAllSelected}
            onClick={onAnswerMultipleMain}
          />
        </Flex>
      </Flex>
    </CSSTransition>
  );
};

export default MultipleMainQuestion;
