import React from "react";
import { Flex, Text } from "rebass";

const severeLevel = {
  SEVERE: {
    text: "Severe",
    color: "#FE274A",
    bgColor: "#FEEBEB",
  },
  MODERATE: {
    text: "Moderate",
    color: "#DF8900",
    bgColor: "#FFF4D9",
  },
  MILD: {
    text: "Mild",
    color: "#10675A",
    bgColor: "#CAFFF3",
  },
};

const SeverityLevelBox = ({ record, width }) => {
  return (
    <Flex
      width={width ? width : 115}
      minHeight={38}
      justifyContent="center"
      alignItems="center"
      color={severeLevel[record?.eightq_severity_level]?.color}
      backgroundColor={severeLevel[record?.eightq_severity_level]?.bgColor}
      css={{
        borderRadius: "7px",
      }}
    >
      <Text fontSize={15} fontWeight={600}>
        {severeLevel[record?.eightq_severity_level]?.text}
      </Text>
    </Flex>
  );
};

export default SeverityLevelBox;
