import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Text, Image, Box } from "rebass";
import { Icon } from "semantic-ui-react";

import {
  authHeader,
  checkSessionByName,
  clearSession,
  sessionName,
} from "../../../session";
import { apiService } from "../../../apiService";
import doctorProfile from "../../../Assets/doctor_default_profile.png";

const DashBoardHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isShowMenu, setShowMenu] = useState(false);
  const [doctorInfo, setDoctorInfo] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const sessionExists = await checkSessionByName(sessionName.MENTAL_DOCTOR, navigate, "/dashboard-login")
      if (sessionExists) {
        getDoctorsInfo();
      }
    }

    checkSession();
  }, [location]);

  const getDoctorsInfo = async () => {
    await apiService(`/doctors/info`, authHeader())
      .then((response) => {
        const responseData = response.data;
        setDoctorInfo(responseData.doctor);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClickLogOut = () => {
    clearSession();
    navigate("/dashboard-login");
  };

  return (
    <Flex
      width={1}
      minHeight={"88px"}
      py={3}
      pr={20}
      justifyContent="flex-end"
      backgroundColor="#206ed1"
      fontFamily="SukhumvitSet-Medium"
      css={{
        position: "relative",
      }}
    >
      <Flex alignItems="center">
        <Box textAlign="right">
          <Text variant="semibold" fontSize={18} color="#fff">
            {doctorInfo
              ? `${doctorInfo.prefix_name} ${doctorInfo.firstname} ${doctorInfo.lastname}`
              : "-"}
          </Text>
          <Text variant="semibold" fontSize={16} color="#fff">
            {doctorInfo?.specialty}
          </Text>
        </Box>
        <Box mx={16}>
          <Image src={doctorProfile} width={76} />
        </Box>
        <Box
          width={20}
          color="#fff"
          onClick={() => {
            setShowMenu(!isShowMenu);
          }}
          css={{
            zIndex: 14,
          }}
        >
          <Icon name={isShowMenu ? "chevron up" : "chevron down"} />
        </Box>
        {isShowMenu && (
          <Box
            sx={{
              boxShadow: "0px 4px 4px 7px rgba(198, 198, 198, 0.25)",
              borderRadius: "9px",
              backgroundColor: "#fff",
              position: "absolute",
              bottom: -28,
              right: 3,
            }}
            width={200}
          >
            <Flex
              p={2}
              textAlign="start"
              mx={56}
              alignItems="center"
              onClick={onClickLogOut}
            >
              <Box color="#FC3333" sx={{ position: "absolute", left: 24 }}>
                <Icon name="power off"></Icon>
              </Box>
              <Text
                variant="bold"
                fontSize={17}
                color="#000"
                css={{
                  whiteSpace: "nowrap",
                }}
              >
                ออกจากระบบ
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default DashBoardHeader;
