import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import _, { ceil } from "lodash";
import { Flex, Image } from "rebass";
import { Pagination } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import FileSaver from "file-saver";

import { apiService } from "../../apiService";
import { authHeader, checkSessionByName, sessionName } from "../../session";
import { eightQSeverity } from "../../models/severity";

import CountryDropDown from "./Components/CountryDropDown";
import HeaderTextTable from "./Components/HeaderTextTable";
import SearchInput from "./Components/SearchInput";
import TabbarBox from "./Components/TabbarBox";
import TableContent from "./Components/TableContent";
import DashBoardHeader from "./Components/DashBoardHeader";
import StatisticContent from "./Components/StatisticContent";
import ExportButton from "./Components/ExportButton";
import SeverityDropDown from "./Components/SeverityDropDown";
import DatePickerInput from "./Components/DatePickerInput";
import search_empty_icon from "../../Assets/search-empty-icon.svg";

const statusType = {
  newcase: "new",
  done: "done",
};

const tabType = {
  NEWCASE: "new",
  DONE: "done",
  STATISTICS: "statistics",
  FOLLOWUP: "follow_up",
};

const defaultStatistics = {
  total_record: { severe_count: 0, moderate_count: 0, mild_count: 0 },
  answered_record: {
    answered_severe_count: 0,
    answered_moderate_count: 0,
    answered_mild_count: 0,
  },
};

const MentalDashboardPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [records, setRecords] = useState([]);
  const [totalCases, setTotalCases] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState(tabType.NEWCASE);
  const [statistics, setStatistics] = useState(defaultStatistics);
  const [currentCaseId, setCurrentCaseId] = useState(null);
  const [currentInput, setCurrentInput] = useState("");
  const [provinceAreas, setProvinceAreas] = useState([]);
  const [selectedProvinces, setSelectedProvinces] = useState([]);
  const [selectedSeverityLevels, setSelectedSeverityLevels] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      await checkSessionByName(
        sessionName.MENTAL_DOCTOR,
        navigate,
        "/dashboard-login"
      );
    };
    checkSession();

    setupFilterData();

    getProvinceAreas();
  }, [location]);

  useEffect(() => {
    const refreshData = setInterval(() => {
      setupFilterData();
    }, 1000*60*3);
    return clearInterval(refreshData);
  }, []);

  const setupFilterData = () => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    setCurrentTab(tab);

    const page = searchParams.get("page");
    setCurrentPage(page ? +page : 1);
    const size = searchParams.get("page_size");
    setPageSize(size ? +size : 10);

    const caseId = searchParams.get("case_id");
    setCurrentCaseId(caseId);
    const search = searchParams.get("search");
    setCurrentInput(search);

    const provinceList = searchParams.getAll("provinces");
    const provinces =
      provinceList && provinceList.length > 0 ? provinceList : [];
    setSelectedProvinces(provinces);

    const severityLevels = searchParams.getAll("severity_level");
    const allSeverityLevels = searchParams.get("severity_level") === "ALL";
    const severities =
      severityLevels && severityLevels.length > 0
        ? severityLevels
        : [eightQSeverity.SEVERE, eightQSeverity.MODERATE];
    const selectedSeverities = allSeverityLevels
      ? [eightQSeverity.SEVERE, eightQSeverity.MODERATE, eightQSeverity.MILD]
      : severities;
    setSelectedSeverityLevels(selectedSeverities);

    const start = searchParams.get("start_date");
    setStartDate(start);
    const end = searchParams.get("end_date");
    setEndDate(end);

    switch (tab) {
      case tabType.STATISTICS:
        getStatisticRecord(provinces, start, end);
        break;
      default:
        getRecord(
          page ? +page : 1,
          size ? +size : 10,
          tab,
          caseId,
          search,
          provinces,
          selectedSeverities,
          start,
          end
        );
        break;
    }
  };

  const getRecord = async (
    page,
    size,
    status,
    caseId,
    search,
    provinces,
    severities,
    start,
    end
  ) => {
    let req = {
      page_number: page,
      page_size: size,
      status: status,
      provinces: provinces,
      severities: severities,
    };

    if (caseId) {
      req = { ...req, record_id: +caseId };
    }
    if (search) {
      req = { ...req, filter_text: search };
    }
    if (start) {
      req = { ...req, start_date: start };
    }
    if (end) {
      req = { ...req, end_date: end };
    }

    console.log(req);

    setRecords([]);
    setIsLoading(true);
    await apiService
      .post(`/mental/dashboard`, req, authHeader())
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          setRecords(responseData.records);
          setTotalCases(responseData.total_records);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        if (err.response?.status === 500) {
          enqueueSnackbar("เกิดข้อผิดพลาด ขณะนี้ระบบขัดข้อง", { variant: "error" })
        } else if (err.response?.status === 401) {
          enqueueSnackbar("คุณไม่ได้เข้าสู่ระบบ/session ไม่ถูกต้อง", { variant: "error" })
        } else {
          enqueueSnackbar(err.message, { variant: "error" })
        }
      });
  };

  const getStatisticRecord = async (provinces, start, end) => {
    let req = {
      provinces: provinces,
    };
    if (start) {
      req = { ...req, start_date: start };
    }
    if (end) {
      req = { ...req, end_date: end };
    }

    await apiService
      .post("/mental/dashboard/stat", req, authHeader())
      .then((res) => {
        console.log(res);
        const responseData = res.data;
        if (responseData) {
          setStatistics(responseData);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 500) {
          enqueueSnackbar("เกิดข้อผิดพลาด ขณะนี้ระบบขัดข้อง", { variant: "error" })
        } else if (err.response?.status === 401) {
          enqueueSnackbar("คุณไม่ได้เข้าสู่ระบบ/session ไม่ถูกต้อง", { variant: "error" })
        } else {
          enqueueSnackbar(err.message, { variant: "error" })
        }
      });
  };

  const getProvinceAreas = async () => {
    await apiService
      .get("/mental/province_areas", authHeader())
      .then((res) => {
        const responseData = res.data.province_areas;
        if (responseData) {
          setProvinceAreas(responseData);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 500) {
          enqueueSnackbar("เกิดข้อผิดพลาด ขณะนี้ระบบขัดข้อง", { variant: "error" })
        } else if (err.response?.status === 401) {
          enqueueSnackbar("คุณไม่ได้เข้าสู่ระบบ/session ไม่ถูกต้อง", { variant: "error" })
        } else {
          enqueueSnackbar(err.message, { variant: "error" })
        }
      });
  };

  const onClickTab = (tab) => {
    setRecords([]);
    setCurrentTab(tab);

    handleFilterSearch(
      tab,
      1,
      pageSize,
      currentCaseId,
      currentInput,
      selectedProvinces,
      selectedSeverityLevels,
      startDate,
      endDate
    );
  };

  const getSearchParams = (
    tab,
    page,
    size,
    caseId,
    search,
    provinces,
    severities,
    start,
    end
  ) => {
    let searchParams = new URLSearchParams(location.search);

    const switchTab = currentTab !== tab;

    searchParams.set("tab", tab);

    if (tab !== tabType.STATISTICS) {
      searchParams.set("page", switchTab ? 1 : page);
    } else {
      searchParams.delete("page");
    }

    if (size && tab !== tabType.STATISTICS) {
      searchParams.set("page_size", size);
    } else {
      searchParams.delete("page_size");
    }

    if (caseId && !switchTab && tab !== tabType.STATISTICS) {
      searchParams.set("case_id", caseId);
    } else {
      searchParams.delete("case_id");
    }

    if (search && !switchTab && tab !== tabType.STATISTICS) {
      searchParams.set("search", search);
    } else {
      searchParams.delete("search");
    }

    if (switchTab) {
      setCurrentInput(null);
    }

    if (provinces && provinces.length > 0) {
      searchParams.delete("provinces");
      _.forEach(provinces, (province) =>
        searchParams.append("provinces", province)
      );
    } else {
      searchParams.delete("provinces");
    }

    if (severities) {
      searchParams.delete("severity_level");
      if (severities.length === 0 || severities.length === 3) {
        searchParams.set("severity_level", "ALL");
      } else {
        _.forEach(severities, (severity) =>
          searchParams.append("severity_level", severity)
        );
      }
    } else {
      searchParams.delete("severity_level");
    }

    if (start) {
      searchParams.set("start_date", start);
    } else {
      searchParams.delete("start_date");
    }

    if (end) {
      searchParams.set("end_date", end);
    } else {
      searchParams.delete("end_date");
    }

    return searchParams.toString();
  };

  const handleFilterSearch = (
    tab,
    page,
    size,
    caseId,
    search,
    provinces,
    severities,
    start,
    end
  ) => {
    let searchParams = getSearchParams(
      tab,
      page,
      size,
      caseId,
      search,
      provinces,
      severities,
      start,
      end
    );

    navigate(`?${searchParams}`);
    document.scrollingElement.scrollTo({ top: 0 });
  };

  const handlePageChange = (event, value) => {
    let searchParams = getSearchParams(
      currentTab,
      value,
      pageSize,
      currentCaseId,
      currentInput,
      selectedProvinces,
      selectedSeverityLevels,
      startDate,
      endDate
    );

    navigate(`?${searchParams}`);
    document.scrollingElement.scrollTo({ top: 0 });
  };

  const onChangeCaseIdInput = (id) => {
    setCurrentCaseId(id);
    handleFilterSearch(
      currentTab,
      1,
      pageSize,
      id,
      currentInput,
      selectedProvinces,
      selectedSeverityLevels,
      startDate,
      endDate
    );
  };

  const onChangeSearchInput = (search) => {
    setCurrentInput(search);
    handleFilterSearch(
      currentTab,
      1,
      pageSize,
      currentCaseId,
      search,
      selectedProvinces,
      selectedSeverityLevels,
      startDate,
      endDate
    );
  };

  const onClickRecord = (recordId) => {
    if (!recordId) return;

    let selection = window.getSelection().toString();
    if (selection.length <= 0) {
      navigate(`/patient-info${location.search}`, { state: { recordId } });
    }
  };

  const onApplyFilterProvinces = (provinces) => {
    setSelectedProvinces(provinces);
    handleFilterSearch(
      currentTab,
      1,
      pageSize,
      currentCaseId,
      currentInput,
      provinces,
      selectedSeverityLevels,
      startDate,
      endDate
    );
  };

  const onApplyFilterSeverity = (severities) => {
    setSelectedSeverityLevels(severities);
    handleFilterSearch(
      currentTab,
      1,
      pageSize,
      currentCaseId,
      currentInput,
      selectedProvinces,
      severities,
      startDate,
      endDate
    );
  };

  const onApplyFilterDate = (start, end) => {
    console.log(start, end);
    handleFilterSearch(
      currentTab,
      1,
      pageSize,
      currentCaseId,
      currentInput,
      selectedProvinces,
      selectedSeverityLevels,
      start,
      end
    );
  };

  const onClickExport = async () => {
    let req = {
      status: statusType[currentTab],
      provinces: selectedProvinces,
      severities: selectedSeverityLevels,
    };

    if (currentInput) {
      req = { ...req, filter_text: currentInput };
    }
    if (startDate) {
      req = { ...req, start_date: startDate };
    }
    if (endDate) {
      req = { ...req, end_date: endDate };
    }

    await apiService
      .post(`/mental/dashboard/csv`, req, authHeader())
      .then((response) => {
        const responseData = response.data;
        if (responseData) {
          const csvData = new Blob([responseData], {
            type: "text/csv;charset=utf-8;",
          });
          const timeStamp = Math.floor(new Date().getTime() / 1000);
          FileSaver.saveAs(
            csvData,
            `mhorprom_mental_dashboard_${timeStamp}.csv`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 500) {
          enqueueSnackbar("เกิดข้อผิดพลาด ขณะนี้ระบบขัดข้อง", { variant: "error" })
        } else if (err.response?.status === 401) {
          enqueueSnackbar("คุณไม่ได้เข้าสู่ระบบ/session ไม่ถูกต้อง", { variant: "error" })
        } else {
          enqueueSnackbar(err.message, { variant: "error" })
        }
      });
  };

  return (
    <Flex
      minHeight="100vh"
      pb={100}
      flexDirection="column"
      backgroundColor="#DEF6FF"
    >
      <DashBoardHeader />

      <Flex width="90%" flexDirection="column" alignSelf="center">
        {/* Sub Menus */}
        <Flex
          mt={84}
          width={1}
          minHeight={64}
          alignItems="flex-start"
          justifyContent="center"
        >
          <TabbarBox
            text={"New cases"}
            onSelected={currentTab === tabType.NEWCASE}
            onClick={() => onClickTab(tabType.NEWCASE)}
            disabled={currentTab === tabType.NEWCASE}
          />
          <TabbarBox
            text={"Follow up"}
            onSelected={currentTab === tabType.FOLLOWUP}
            onClick={() => onClickTab(tabType.FOLLOWUP)}
            disabled={currentTab === tabType.FOLLOWUP}
          />
          <TabbarBox
            text={"Done"}
            onSelected={currentTab === tabType.DONE}
            onClick={() => onClickTab(tabType.DONE)}
            disabled={currentTab === tabType.DONE}
          />
          <TabbarBox
            text={"Statistic"}
            onSelected={currentTab === tabType.STATISTICS}
            onClick={() => onClickTab(tabType.STATISTICS)}
            disabled={currentTab === tabType.STATISTICS}
          />
          <Flex flex={5} />

          <Flex>
            <ExportButton onClick={onClickExport} />
          </Flex>
        </Flex>
        <Flex
          width={1}
          minHeight={700}
          alignItems="center"
          flexDirection="column"
          px={20}
          pb={50}
          css={{
            background: "#fff",
            boxShadow: "0px 2px 2px 2px rgba(167, 167, 167 ,0.25)",
            zIndex: 2,
            borderRadius: "0px 9px 9px 9px",
          }}
        >
          <Flex width={1} flexDirection="column">
            {/* searchBar */}
            <Flex pt={20} flexDirection="column">
              <Flex width={1} justifyContent="space-between">
                <Flex flex={1} flexWrap="wrap" justifyContent="flex-start">
                  <SearchInput
                    hidden={currentTab === tabType.STATISTICS}
                    placeholder="ค้นหา Case ID"
                    currentInput={currentCaseId}
                    onChangeSearchInput={onChangeCaseIdInput}
                  />
                  <SearchInput
                    hidden={currentTab === tabType.STATISTICS}
                    placeholder="ค้นหา เบอร์โทรศัพท์/ชื่อ"
                    currentInput={currentInput}
                    onChangeSearchInput={onChangeSearchInput}
                  />
                </Flex>
                <Flex flex={1} flexWrap="wrap" justifyContent="flex-end">
                  <CountryDropDown
                    tab={currentTab === tabType.STATISTICS}
                    data={provinceAreas}
                    selectedValue={selectedProvinces}
                    onApply={onApplyFilterProvinces}
                  />
                  <SeverityDropDown
                    hidden={currentTab === tabType.STATISTICS}
                    selectedSeverityLevels={selectedSeverityLevels}
                    onApply={onApplyFilterSeverity}
                  />
                  <DatePickerInput
                    start={startDate}
                    end={endDate}
                    onApply={onApplyFilterDate}
                  />
                </Flex>
              </Flex>
            </Flex>
            {/* containner newcase and done  */}
            <Flex
              width={1}
              minHeight={600}
              flexDirection="column"
              justifyContent="space-between"
              display={
                location.search?.includes("statistic")
                  ? "none !important"
                  : "flex"
              }
            >
              <Flex
                width={1}
                flexDirection="column"
                sx={{ overflow: "scroll" }}
              >
                {/* table header */}
                <HeaderTextTable />
                {/* table content */}
                <Flex
                  mt={36}
                  width={1}
                  minWidth={800}
                  maxHeight={900}
                  height="auto"
                  flexDirection="column"
                  css={{
                    border: "1px solid #D0DAE9",
                    borderRadius: "4px",
                  }}
                >
                  {isLoading && (
                    <Flex justifyContent="center" alignItems="center">
                      <Player
                        autoplay
                        loop={true}
                        src={
                          "https://assets5.lottiefiles.com/packages/lf20_w6xlywkv.json"
                        }
                        style={{ height: "300px", width: "300px" }}
                      />
                    </Flex>
                  )}

                  {_.map(records, (record, i) => (
                    <TableContent
                      key={i}
                      record={record}
                      onClickRecord={onClickRecord}
                    />
                  ))}

                  {records.length === 0 && !isLoading && (
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      mt={4}
                      mx={3}
                      minHeight={280}
                    >
                      <Image width={80} src={search_empty_icon} />
                    </Flex>
                  )}
                </Flex>
              </Flex>

              <Flex justifyContent="center" mt={36}>
                <Pagination
                  count={totalCases === 0 ? 1 : ceil(totalCases / +pageSize)}
                  color="primary"
                  size="large"
                  onChange={handlePageChange}
                  page={currentPage}
                  defaultValue={currentPage}
                />
              </Flex>
            </Flex>

            {currentTab === tabType.STATISTICS && (
              <StatisticContent statistics={statistics} />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default MentalDashboardPage;
