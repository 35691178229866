import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Flex, Text, Image } from "rebass";
import { Player } from "@lottiefiles/react-lottie-player";

import bg_landing_1 from "../Assets/complete_bg/bg-landing-1.webp";
import bg_landing_2 from "../Assets/complete_bg/bg-landing-2.webp";
import bg_landing_3 from "../Assets/complete_bg/bg-landing-3.webp";
import bg_landing_4 from "../Assets/complete_bg/bg-landing-4.webp";
import rightIcon from "../Assets/right-icon.svg";
import "../Styles/CompleteLandingPage.css";

const bgLandingList = [
  bg_landing_1,
  bg_landing_2,
  bg_landing_3,
  bg_landing_4
]

const CompleteLandingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [bgLanding, setbgLanding] = useState(bg_landing_2);
  const [loading, setLoading] = useState(true);
  const [loadingSuccess, setLoadingSuccess] = useState(false);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    const agnosRecordId = params.get("agnos_record_id");
    setRecordId(+agnosRecordId);

    const userData =
      location.state?.user ?? JSON.parse(localStorage.getItem("user"));
    if (userData) {
      setUser(userData);
    }

    setbgLanding(bgLandingList[randomInt(0,3)]);

    setTimeout(() => {
      setLoading(false);
      setLoadingSuccess(true);
    }, 3000);
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setLoadingSuccess(false);
    }, 2000);
  }, [loadingSuccess]);

  const onClickNext = () => {
    navigate("/diagnosis-result", { state: { recordId }});
  };
  
  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <Flex flex={1} justifyContent="center">
      <Flex
        minHeight="100vh"
        maxWidth={768}
        width={1}
        flexDirection="column"
        justifyContent={(loading || loadingSuccess) ? "center" : "flex-end"}
        css={{
          background: `url(${bgLanding}) no-repeat`,
          backgroundSize: "cover",
        }}
        onClick={onClickNext}
      >
        {!loading && !loadingSuccess && (
          <>
            <Text
              className="fadein-text"
              textAlign="center"
              fontFamily="Mitr"
              color="#fff"
              fontSize="2rem"
            >
              อยากให้รู้ว่าเราจะอยู่ตรงนี้<br />
              เราเต็มใจที่จะช่วยคุณนะ
            </Text>
            <Text
              className="link-fadein-text"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              fontFamily="Mitr"
              color="#fff"
              fontSize="1.3rem"
              fontWeight="200"
              mb={100}
              css={{
                textDecoration: "underline",
              }}
            >
              <a style={{ color: "#FFF" }}>กดที่หน้าจอเพื่อดูผลลัพธ์</a>
              <Image ml={2} src={rightIcon} />
            </Text>
          </>
        )}

        {(loading || loadingSuccess) && (
          <Player
            autoplay={true}
            loop={loading ? true : false}
            src={loading ? "https://assets8.lottiefiles.com/packages/lf20_29hfx8hk.json" : "https://assets4.lottiefiles.com/packages/lf20_mkelocrp.json"}
            style={{ height: "300px", width: "300px" }}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default CompleteLandingPage;
