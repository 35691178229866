import React from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Image } from "rebass";

import ProgressBar from "../ProgressBar";
import close_icon from "../../Assets/close-icon.svg";

const ProgressNavbar = ({ progress }) => {
  const navigate = useNavigate();

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      backgroundColor="#F2E1C1"
      minHeight="10vh"
      height="10vh"
      width={1}
      pt={"env(safe-area-inset-top, 20px)"}
      px={20}
      pb={20}
      sx={{
        position: "relative",
        borderRadius: "0px 0px 8px 8px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      }}
    >
      {/* Progress */}
      <ProgressBar progress={progress} />

      {/* Close */}
      <Flex>
        <Image
          src={close_icon}
          width={52}
          height={52}
          mt={2}
          onClick={() => navigate("/")}
        />
      </Flex>
    </Flex>
  );
};

export default ProgressNavbar;
