import _ from "lodash";
import React from "react";
import { Flex, Text } from "rebass";

const PatientSymptomBox = ({ summary }) => {
  const { yes_symptoms, no_symptoms, idk_symptoms } = summary;

  return (
    <>
      <Flex
        mt={3}
        width={1}
        css={{
          gap: "36px",
          fontFamily: "SukhumvitSet-Medium",
          flexWrap: "wrap"
        }}
      >
        {/* Yes Symptoms */}
        <Flex
          flex={1}
          minHeight={380}
          minWidth={450}
          flexDirection="column"
          css={{
            background: "#fff",
            boxShadow: "0px 0px 0px 2px rgba(197, 197, 197, 0.25)",
            borderRadius: "7px",
            padding: "1.6rem 2rem",
          }}
          textAlign="center"
        >
          <span style={{ fontSize: "1.4rem", fontWeight: 600 }}>มีอาการ</span>
          {yes_symptoms.length === 0 && (
            <Text mt={24} textAlign="start" lineHeight="2rem">
              <p style={{ fontSize: "1.4rem", color: "#000" }}>ไม่มี</p>
            </Text>
          )}
          {_.map(yes_symptoms, (symptom, i) => {
            return (
              <Text key={`symptom-${symptom.symptom_key}-${i}`} mt={24} textAlign="start" lineHeight="2rem">
                <ul
                  style={{
                    color: "#206ED1",
                    fontSize: "1.2rem",
                  }}
                >
                  {symptom.symptom_text}
                </ul>
                {_.map(symptom.answers, (answer) => {
                  return (
                    <li
                      key={`yes-symptoms-answer-${answer}`}
                      style={{
                        color: "#38454D",
                        fontSize: "1.4rem",
                      }}
                    >
                      {answer}
                    </li>
                  );
                })}
              </Text>
            );
          })}
        </Flex>

        {/* No Symptoms */}
        <Flex
          flex={1}
          minHeight={380}
          minWidth={450}
          flexDirection="column"
          css={{
            background: "#fff",
            boxShadow: "0px 0px 0px 2px rgba(197, 197, 197, 0.25)",
            borderRadius: "7px",
            padding: "1.6rem 2rem",
          }}
          textAlign="center"
        >
          <span style={{ fontSize: "1.4rem", fontWeight: 600 }}>
            ไม่มีอาการ
          </span>
          <Text
            mt={24}
            textAlign="start"
            lineHeight="2rem"
            fontSize="1.2rem"
            color="#206ed1"
          >
            {no_symptoms.length === 0 && (
              <p style={{ fontSize: "1.4rem", color: "#000" }}>ไม่มี</p>
            )}
            {_.map(no_symptoms, (symptom) => {
              return <p key={`no-symptoms-${symptom.symptom_key}`}>{symptom.symptom_text}</p>;
            })}
          </Text>
        </Flex>
      </Flex>

      {/* IDK Symptoms */}
      <Flex
        mt={4}
        flex={1}
        minHeight={180}
        minWidth={450}
        flexDirection="column"
        css={{
          background: "#fff",
          boxShadow: "0px 0px 0px 2px rgba(197, 197, 197, 0.25)",
          borderRadius: "7px",
          padding: "1.6rem 2rem",
        }}
        textAlign="center"
      >
        <span style={{ fontSize: "1.4rem", fontWeight: 600 }}>ไม่ทราบ</span>
        <Text
          mt={24}
          textAlign="start"
          lineHeight="2rem"
          fontSize="1.2rem"
          color="#206ed1"
        >
          {idk_symptoms.length === 0 && (
            <p style={{ fontSize: "1.4rem", color: "#000" }}>ไม่มี</p>
          )}
          {_.map(idk_symptoms, (symptom) => {
            return <p key={`idk-symptoms-${symptom.symptom_key}`}>{symptom.symptom_text}</p>;
          })}
        </Text>
      </Flex>
    </>
  );
};

export default PatientSymptomBox;
