import React from "react";
import { Button, Image } from "rebass";
import export_icon from "../../../Assets/export-icon.svg";

const ExportButton = ({ onClick }) => {
  return (
    <Button
      display="flex"
      width={140}
      backgroundColor="#206ED1"
      color="#fff"
      fontFamily="SukhumvitSet-Medium"
      fontSize={16}
      p="8px"
      sx={{
        "&:hover": {
          backgroundColor: "#005ED2",
        },
      }}
      onClick={onClick}
    >
      <Image src={export_icon} width={24} height={24} mr="4px" />
      Export to CSV
    </Button>
  );
};

export default ExportButton;
