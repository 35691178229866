import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { Checkbox, Input, Label } from "@rebass/forms";
import { Button, Flex, Image } from "rebass";

import homeIcon from "../../../Assets/home-icon.svg";
import arrowDownIcon from "../../../Assets/arrow-down-icon.svg";

const CountryDropDown = ({ data, selectedValue, onApply, tab }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [dropDownAreas, setDropDownAreas] = useState({});
  const [selectedProvinces, setSelectedProvinces] = useState({});
  const [selectedAllAreas, setSelectedAllAreas] = useState(false);

  const { register, setValue } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    let areas = {};
    let provincesData = {};
    _.forEach(data, (provinces, area) => {
      const selected = _.intersectionBy(provinces, selectedValue);
      areas[area] = { provinces, selected: selected.length > 0 };
      setValue(area, selected.length === provinces.length);

      _.forEach(provinces, (province) => {
        const provinceSelected = _.includes(selectedValue, province);
        provincesData[province] = { province, selected: provinceSelected };
        setValue(`${area}-${province}`, provinceSelected);
      });
    });
    setDropDownAreas(areas);
    setSelectedProvinces(provincesData);
  }, [data, selectedValue, selectedAllAreas]);

  const onSelectAreas = (checked, key, provinces) => {
    setDropDownAreas({
      ...dropDownAreas,
      [`${key}`]: { ...dropDownAreas[key], selected: checked },
    });

    let provincesData = selectedProvinces;
    _.forEach(provinces, (province) => {
      provincesData[province] = { province, selected: checked };
      setValue(`${key}-${province}`, checked);
    });
    setSelectedProvinces(provincesData);
  };

  const onSelectedProvinces = (checked, key, area, areaProvinces) => {
    const provinces = {
      ...selectedProvinces,
      [`${key}`]: { ...selectedProvinces[key], selected: checked },
    }
    setSelectedProvinces(provinces);

    checkAllProvincesInAreaSelected(area, provinces, areaProvinces)
  };

  const checkAllProvincesInAreaSelected = (area, provinces, areaProvinces) => {
    const selected = _.filter(provinces, (province) => province.selected);
    const allProvincesSelected = selected.length === areaProvinces.length;
    console.log(allProvincesSelected, selected, areaProvinces);
    setDropDownAreas({
      ...dropDownAreas,
      [`${area}`]: {
        ...dropDownAreas[area],
        selected: allProvincesSelected,
      },
    });
    setValue(area, allProvincesSelected);
  };

  const onClickApply = () => {
    const selected = _.filter(
      selectedProvinces,
      (province) => province.selected
    );
    const provinces = _.map(selected, (p) => p.province);

    console.log(provinces);

    if (selectedAllAreas) {
      setSelectedProvinces({});
      onApply([]);
    } else {
      onApply(_.uniq(provinces));
    }
    setShowDropDown(false);
  };

  return (
    <Flex
      flexDirection="column"
      mr={tab ? "2" : "0"}
      mt={12}
      css={{
        position: "relative",
      }}
    >
      <Label
        width={200}
        minHeight={38}
        px={2}
        alignItems="center"
        css={{
          boxShadow:
            "0px 0.0851744px 0.255523px rgba(0, 0, 0, 0.1), 0px 0.851744px 1.70349px rgba(0, 0, 0, 0.2)",
          borderRadius: ".5rem",
        }}
      >
        <Image width={26} src={homeIcon} />
        <Input
          placeholder="พื้นที่"
          readOnly
          value={
            selectedAllAreas
              ? "(ทั้งหมด)"
              : selectedValue?.length > 0
              ? `(${selectedValue?.length} selected)`
              : ""
          }
          css={{
            border: "none",
            outline: "none",
            fontFamily: "Mitr",
            fontWeight: 400,
            fontSize: ".9rem",
            color: "#1034A6",
            "::placeholder": {
              color: "rgba(16, 52, 166, 0.5)",
            },
            cursor: "pointer",
          }}
          onClick={() => {
            setShowDropDown(!showDropDown);
          }}
        />
        <Image width={16} src={arrowDownIcon} />
      </Label>
      <Flex
        display={showDropDown ? "flex" : "none !important"}
        mt={1}
        pt={2}
        flexDirection="column"
        width={200}
        maxHeight={500}
        alignItems="center"
        css={{
          boxShadow: "0px 4px 30px rgba(37, 37, 37, 0.1)",
          background: "#fff",
          zIndex: 4,
          overflow: "scroll",
          position: "absolute",
          fontFamily: "Mitr",
          top: 40,
          borderRadius: "6px",
        }}
      >
        <Flex
          py={2}
          width={1}
          pl={1}
          justifyContent="center"
          css={{ borderBottom: "1px solid #D0DAE9", fontFamily: "Mitr" }}
          flexDirection="column"
        >
          <Label width={"90%"}>
            <Checkbox
              onClick={() => {
                setSelectedAllAreas(!selectedAllAreas);
              }}
            />
            ทั้งหมด
          </Label>
        </Flex>
        {!selectedAllAreas &&
          _.map(data, (provinces, area) => (
            <Flex width={1} flexDirection="column" key={area}>
              <Flex
                py={2}
                pl={1}
                width={1}
                key={area}
                justifyContent="center"
                css={{ borderBottom: "1px solid #D0DAE9" }}
                flexDirection="column"
              >
                <Label width={"90%"}>
                  <Checkbox
                    {...register(area)}
                    onClick={(e) =>
                      onSelectAreas(e.target.checked, area, provinces)
                    }
                  />
                  {area}
                </Label>
              </Flex>
              {_.map(provinces, (province, i) => (
                <Label
                  pl={3}
                  py={2}
                  key={i}
                  width={1}
                  css={{ borderBottom: "1px solid #D0DAE9" }}
                >
                  <Checkbox
                    {...register(`${area}-${province}`)}
                    onClick={(e) => {
                      onSelectedProvinces(e.target.checked, province, area, provinces);
                    }}
                  />
                  {province}
                </Label>
              ))}
            </Flex>
          ))}

        <Flex
          py={2}
          width={1}
          justifyContent="center"
          alignItems="center"
          backgroundColor="#fff"
          css={{
            borderBottom: "1px solid #D0DAE9",
            fontFamily: "Mitr",
            position: "sticky",
            bottom: 0,
          }}
        >
          <Button
            width={67}
            backgroundColor="#206ED1"
            color="#fff"
            onClick={onClickApply}
          >
            Apply
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CountryDropDown;
