import React from "react";
import { Input, Label } from "@rebass/forms";
import { Image } from "rebass";
import searchIcon from "../../../Assets/search-icon.svg";

const SearchInput = ({ hidden, placeholder, currentInput, onChangeSearchInput }) => {
  return (
    <Label
      width={240}
      height={38}
      px={2}
      mt={12}
      mr={2}
      alignItems="center"
      css={{
        boxShadow:
          "0px 0.0851744px 0.255523px rgba(0, 0, 0, 0.1), 0px 0.851744px 1.70349px rgba(0, 0, 0, 0.2)",
        borderRadius: "7px",
        visibility: hidden ? "hidden" : "visible",
      }}
    >
      <Image width={26} src={searchIcon} />
      <Input
        placeholder={placeholder ? placeholder : "ค้นหา"}
        css={{
          border: "none",
          outline: "none",
          fontFamily: "Mitr",
          fontWeight: 400,
          fontSize: ".9rem",
          "::placeholder": {
            color: "rgba(16, 52, 166, 0.5)",
          },
        }}
        value={currentInput ? currentInput : ""}
        onChange={(e) => onChangeSearchInput(e.target.value)}
      />
    </Label>
  );
};

export default SearchInput;
