import React, { useEffect, useState } from "react";
import { Flex, Text } from "rebass";
import { useLocation, useNavigate } from "react-router-dom";

import { hamdSeverity } from "../models/severity";
import NavBar from "../Components/Navbar";
import Star from "../Components/Star";
import feedbackBg from "../Assets/feedback-bg.svg";

const UserRatingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [severity, setSeverity] = useState(hamdSeverity.NORMAL);
  const [consentAccepted, setConsentAccepted] = useState(false);

  const [ratingValue, setRatingValue] = useState(0);

  useEffect(() => {
    setSeverity(location.state?.severity ?? hamdSeverity.NORMAL);
    setConsentAccepted(location.state?.consent ?? false);
  }, [location]);

  const onClickRatingStar = (value) => {
    setRatingValue(value);
    setTimeout(() => {
      if (value <= 3) {
        navigate("/feedback-question", {
          state: {
            rating: value,
            severity,
            consent: consentAccepted,
          },
        });
      } else {
        const feedback = {
          rating_score: value,
          too_many_questions: false,
          time_comsumption: false,
          unsatisfied_answer: false,
          ambiguous_instructions: false,
        };
        navigate("/feedback-user", {
          state: {
            severity,
            consent: consentAccepted,
            feedback
          },
        });
      }
    }, 1000);
  };

  const onClickSkip = () => {
    navigate("/complete", {
      state: { severity, consent: consentAccepted },
    });
  };

  return (
    <Flex
      minHeight="100vh"
      width={1}
      flexDirection="column"
      alignItems="center"
      css={{
        background: `#FDFEF9 url(${feedbackBg}) no-repeat bottom left`,
      }}
    >
      <NavBar
        title="แสดงความคิดเห็น"
        backOnly
        fontSize="1.4rem"
        fontWeight={400}
      />
      <Flex
        pb={20}
        minHeight={"80vh"}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth={768}
        width="90%"
        fontFamily="Mitr"
        textAlign="center"
      >
        <Text>
          <h3
            style={{
              fontSize: "1.6rem",
              color: "#1E1446"
            }}
          >
            คุณมีความพอใจ
            <br />
            ในบริการโดยรวม ระดับใด
          </h3>
        </Text>
        <Star ratingValue={ratingValue} onClick={onClickRatingStar} />
        <Text
          mt={64}
          fontSize="1rem"
          fontWeight={300}
          color="#1E1446"
          css={{
            textDecoration: "underline",
          }}
          onClick={onClickSkip}
        >
          ข้าม
        </Text>
      </Flex>
    </Flex>
  );
};

export default UserRatingPage;
