import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { Flex, Text, Image, Button } from "rebass";

import { apiService } from "../apiService";
import { authHeader, checkSessionByName, sessionName } from "../session";
import { eightQSeverity, hamdSeverity } from "../models/severity";
import ConsentModal from "../Components/ConsentModal";
import ConfirmDialog from "../Components/Modal/ConfirmDialog";

import severe_focus_ring from "../Assets/severity/severe-focus-ring.svg";
import moderate_focus_ring from "../Assets/severity/moderate-focus-ring.svg";
import mild_focus_ring from "../Assets/severity/mild-focus-ring.svg";
import normal_focus_ring from "../Assets/severity/normal-focus-ring.svg";

import severity_severe_icon from "../Assets/severity/severity-severe-icon.svg";
import severity_moderate_icon from "../Assets/severity/severity-moderate-icon.svg";
import severity_mild_icon from "../Assets/severity/severity-mild-icon.svg";
import severity_normal_icon from "../Assets/severity/severity-normal-icon.svg";
import arrow_right_icon from "../Assets/arrow-right-circle.svg";

const hamdSeverityData = {
  SEVERE: {
    textColor: "#A63F26",
    focusRingImg: severe_focus_ring,
    focusRingBottom: -20,
    focusRingHorizontal: "30%",
    severityIcon: severity_severe_icon,
    shadow:
      "radial-gradient(61.54% 61.54% at 50% 50%, rgba(166, 63, 38, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
    background:
      "radial-gradient(137.15% 148.29% at 25% 32.33%, rgba(255, 165, 60, 0.3) 0%, rgba(0, 0, 0, 0) 100%), #A63F26",
    border: "2px solid #A85D10",
  },
  MODERATE: {
    textColor: "#CC6600E5",
    focusRingImg: moderate_focus_ring,
    focusRingBottom: -20,
    focusRingHorizontal: "20%",
    severityIcon: severity_moderate_icon,
    shadow:
      "radial-gradient(61.54% 61.54% at 50% 50%, rgba(208, 117, 24, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
    background:
      "radial-gradient(180.22% 125.8% at 22.66% 27.41%, rgba(255, 232, 198, 0.3) 0%, rgba(0, 0, 0, 0) 100%), rgba(204, 102, 0, 0.9)",
    border: "2px solid #A85D10",
  },
  MILD: {
    textColor: "#CCA300",
    focusRingImg: mild_focus_ring,
    severityIcon: severity_mild_icon,
    focusRingBottom: -20,
    focusRingHorizontal: "30%",
    shadow:
      "radial-gradient(61.54% 61.54% at 50% 50%, rgba(204, 163, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
    background:
      "radial-gradient(156.25% 524.98% at 13.83% 28.57%, rgba(218, 220, 132, 0.4) 0%, rgba(0, 0, 0, 0) 100%), #CCA300",
    border: "2px solid #948922",
  },
  NORMAL: {
    textColor: "#69A868",
    focusRingBottom: 20,
    focusRingHorizontal: "24%",
    focusRingImg: normal_focus_ring,
    severityIcon: severity_normal_icon,
    shadow:
      "radial-gradient(61.54% 61.54% at 50% 50%, rgba(130, 220, 126, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
    background:
      "radial-gradient(156.25% 524.98% at 13.83% 28.57%, rgba(218, 220, 132, 0.4) 0%, rgba(0, 0, 0, 0) 100%), #62BC6A",
    border: "2px solid #528951",
  },
};

const DiagnosisResultPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [diagnosisRecordId, setDiagnosisRecordId] = useState(null);
  const [diagnosisResult, setDiagnosisResult] = useState(null);
  const [hamd, setHamd] = useState(null);
  const [eightq, setEightq] = useState(null);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [consentAccepted, setConsentAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    // const checkUserSession = async () => {
    //   await checkSessionByName(sessionName.MENTAL, navigate, "/")
    // }
    // checkUserSession();

    const recordId = location.state?.recordId;
    setDiagnosisRecordId(recordId);

    const result = JSON.parse(localStorage.getItem("diagnosis_result"));
    if (!result || result.record_id !== recordId) {
      setErrorMessage("ข้อมูลเกิดข้อผิดพลาด");
      return;
    }

    setDiagnosisResult(result);
    setHamd(result.hamd_severity_level);
    setEightq(result.eightq_severity_level);
  }, [location]);

  useEffect(() => {
    if (!diagnosisRecordId) return;
    // getDiagnosisRecord(diagnosisRecordId);
  }, [diagnosisRecordId])

  const getDiagnosisRecord = async (id) => {
    await apiService
      .get(`/mental/mental_records/${id}`, authHeader())
      .then((response) => {
        const responseData = response.data;
        setDiagnosisResult(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const onClickNext = () => {
    if (!diagnosisResult) return;

    switch (eightq) {
      case eightQSeverity.SEVERE:
        onAcceptConsent();
        break;
      case eightQSeverity.MODERATE:
        setIsConsentModalOpen(true);
        break;
      default:
        navigate("/feedback-rating", {
          state: { severity: eightq, consent: false },
        });
        break;
    }
  };

  const onAcceptConsent = async () => {
    if (!diagnosisResult) return;
    setConsentAccepted(true);

    await apiService
      .get(
        `/mental/mental_records/${diagnosisResult.record_id}/consent`,
        authHeader()
      )
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === "success") {
          switch (eightq) {
            case eightQSeverity.SEVERE:
            case eightQSeverity.MODERATE:
              navigate("/personal-info", { state: { severity: eightq } });
              break;
            default:
              navigateToFeedback();
              break;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateToFeedback = () => {
    navigate("/feedback-rating", {
      state: { severity: eightq, consent: consentAccepted },
    });
  };

  return (
    <Flex
      flex={1}
      justifyContent="center"
      css={{
        background: "#F7F8F3",
        position: "relative",
      }}
    >
      <Flex
        minHeight="100vh"
        maxWidth={768}
        width={1}
        flexDirection="column"
        alignItems="center"
      >
        {/* Results */}
        <Flex
          alignItems="center"
          justifyContent="center"
          css={{ position: "relative" }}
        >
          <Text width="80%" mt={36} textAlign="center" fontFamily="Mitr">
            <h4
              style={{
                fontSize: "1.4rem",
                fontWeight: 400,
                color: "#1E1446",
              }}
            >
              ผลการประเมิน
            </h4>
            <h2
              style={{
                fontSize: "2rem",
                fontWeight: 500,
                color: hamdSeverityData[hamd]?.textColor,
              }}
            >
              {diagnosisResult?.hamd_display_word}
            </h2>
          </Text>

          {/* Focus ring decoration */}
          <Image
            src={hamdSeverityData[hamd]?.focusRingImg}
            width={hamd === hamdSeverity.MODERATE ? 180 : 84}
            height={67}
            css={{
              position: "absolute",
              bottom: hamdSeverityData[hamd]?.focusRingBottom,
              left:
                hamd === hamdSeverity.NORMAL
                  ? hamdSeverityData[hamd]?.focusRingHorizontal
                  : "none",
              right:
                hamd !== hamdSeverity.NORMAL
                  ? hamdSeverityData[hamd]?.focusRingHorizontal
                  : "none",
            }}
          />
        </Flex>

        {/* Severity Icon */}
        <Flex
          minHeight={320}
          width={320}
          alignSelf="center"
          justifyContent="center"
          css={{
            background: hamdSeverityData[hamd]?.shadow,
            borderRadius: "50%",
            zIndex: 1,
          }}
        >
          <Image width={150} src={hamdSeverityData[hamd]?.severityIcon} />
        </Flex>

        {/* Detail Box */}
        <Flex
          mt={2}
          width={1}
          flexDirection="column"
          minHeight={400}
          alignItems="center"
          justifyContent="space-around"
          css={{
            background: hamdSeverityData[hamd]?.background,
            borderRadius: "50px 50px 0px 0px",
          }}
          pb={60}
        >
          <Text
            width="70%"
            mt={64}
            fontFamily="Mitr"
            textAlign="center"
            color="#fff"
          >
            <h3 style={{ fontSize: "1.4rem", fontWeight: 400 }}>
              {diagnosisResult?.eightq_display_word}
            </h3>
          </Text>

          {/* Button */}
          <Button
            mt={60}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="80%"
            css={{
              background: "rgba(255, 255, 255, 0.85)",
              border: hamdSeverityData[hamd]?.border,
              borderRadius: ".7rem",
              fontSize: "1.2rem",
              fontFamily: "Mitr",
              color: "#1E1446",
            }}
            onClick={onClickNext}
          >
            ต่อไป
            <Image ml={3} src={arrow_right_icon} />
          </Button>
        </Flex>
      </Flex>

      {/* Consent */}
      <ConsentModal
        isModalOpen={isConsentModalOpen}
        onClickAccept={onAcceptConsent}
        onClickCancel={navigateToFeedback}
      />

      {/* Error Popup Dialog */}
      <ConfirmDialog
        showConfirmDialog={!_.isEmpty(errorMessage)}
        title={errorMessage}
        message="กรุณาลองใหม่อีกครั้ง"
        confirmButtonTitle="กลับสู่หน้าหลัก"
        onConfirm={() => {
          setErrorMessage(null);
          navigate("/");
        }}
      />
    </Flex>
  );
};

export default DiagnosisResultPage;
