import _ from "lodash";
import React from "react";
import { Flex, Text } from "rebass";
import StatisticBox from "./StatisticBox";

const StatisticContent = ({ statistics }) => {
  const { total_record, answered_record } = statistics;
  console.log(total_record.mild_count);

  const formatPercentage = (count, total) => {
    const percentage = (count / total) * 100;
    return +percentage.toFixed(2);
  };

  return (
    <Flex
      width={1}
      css={{
        gap: "20px",
      }}
    >
      <StatisticBox
        headerText={`จำนวน ${
          total_record.severe_count +
          total_record.moderate_count +
          total_record.mild_count
        } เคส`}
        severeNumber={total_record.severe_count}
        moderateNumber={total_record.moderate_count}
        mildNumber={total_record.mild_count}
      />
      <StatisticBox
        headerText="เปอร์เซ็นต์การตอบรับ"
        severeNumber={`${
          total_record.severe_count > 0
            ? formatPercentage(
                answered_record.answered_severe_count,
                total_record.severe_count
              )
            : 0
        }%`}
        moderateNumber={`${
          total_record.moderate_count > 0
            ? formatPercentage(
                answered_record.answered_moderate_count,
                total_record.moderate_count
              )
            : 0
        }%`}
        mildNumber={`${
          total_record.mild_count > 0
            ? formatPercentage(
                answered_record.answered_mild_count,
                total_record.mild_count
              )
            : 0
        }%`}
      />
    </Flex>
  );
};

export default StatisticContent;
