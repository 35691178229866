import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack"
import _ from "lodash";
import { format } from "date-fns";
import { Button, Flex, Text } from "rebass";
import { Textarea } from "@rebass/forms";
import { Player } from "@lottiefiles/react-lottie-player";

import { apiService } from "../../apiService";
import { checkSessionByName, sessionName } from "../../session";

import DashBoardHeader from "./Components/DashBoardHeader";
import PatientContactCheckBox from "./Components/PatientContactCheckBox";
import PatientInfoBox from "./Components/PatientInfoBox";
import PatientRiskScore from "./Components/PatientRiskScore";
import PatientSymptomBox from "./Components/PatientSymptomBox";
import SuccessModal from "../../Components/Modal/SuccessModal";

const defaultSummary = {
  yes_symptoms: [],
  no_symptoms: [],
  idk_symptoms: [],
};

const PatientInfoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [recordId, setRecordId] = useState(null);
  const [record, setRecord] = useState(null);
  const [summary, setSummary] = useState(defaultSummary);
  const [medicalInfoData, setMedicalInfoData] = useState({});
  const [notAcceptedList, setNotAcceptedList] = useState([]);
  const [isDone, setIsDone] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModel, setShowSuccessModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onSubmit",
    shouldFocusError: true,
    defaultValues: medicalInfoData,
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      switch (name) {
        case "is_not_accept_call":
        case "invalid_phone_number":
          if (value.is_not_accept_call || value.invalid_phone_number) {
            setValue("eightq_score", null);
            setValue("eightq_severity_level", null);
            setValue("nineq_score", null);
            setValue("nineq_severity_level", null);
          }
          clearErrors();
          break;
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const checkSession = async () => {
      await checkSessionByName(
        sessionName.MENTAL_DOCTOR,
        navigate,
        "/dashboard-login"
      );
    };

    checkSession();

    setRecordId(location.state?.recordId);
    document.scrollingElement.scrollTo({ top: 0 });
  }, [location]);

  useEffect(() => {
    if (!recordId) return;
    getRecordInfo(recordId);
  }, [recordId]);

  const getRecordInfo = async (recordId) => {
    setIsLoading(true);
    await apiService
      .get(`/mental/mental_records/${recordId}`)
      .then((response) => {
        setIsLoading(false);
        const responseData = response.data;
        setRecord(responseData);
        if (responseData.summary) {
          setSummary(responseData.summary);
        }

        const medicalInfo = responseData.medical_info;
        if (medicalInfo) {
          setValue("eightq_score", medicalInfo.eightq_score);
          setValue("eightq_severity_level", medicalInfo.eightq_severity_level);
          setValue("nineq_score", medicalInfo.nineq_score);
          setValue("nineq_severity_level", medicalInfo.nineq_severity_level);
          setValue("is_not_accept_call", medicalInfo.is_not_accept_call);
          setValue("invalid_phone_number", medicalInfo.invalid_phone_number);
          setIsDone(medicalInfo.is_done ? medicalInfo.is_done : false);
        }
        const notAccepted = _.filter(responseData.medical_logs, (log) =>
          _.includes(log.log_text, "ไม่รับสาย")
        );
        setNotAcceptedList(
          _.sortBy(notAccepted, (log) => new Date(log.pub_date))
        );
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        if (err.response?.status === 500) {
          enqueueSnackbar("เกิดข้อผิดพลาด ขณะนี้ระบบขัดข้อง", { variant: "error" })
        } else if (err.response?.status === 401) {
          enqueueSnackbar("คุณไม่ได้เข้าสู่ระบบ/session ไม่ถูกต้อง", { variant: "error" })
        } else {
          enqueueSnackbar(err.message, { variant: "error" })
        }
      });
  };

  const onSubmit = async (data) => {
    if (!recordId) return;
    setIsLoading(true);
    setDisableButton(true);
    setTimeout(() => {
      setDisableButton(false);
    }, 3000);
    let req = {
      ...data,
      eightq_score: data.eightq_score ? +data.eightq_score : data.eightq_score,
      nineq_score: data.nineq_score ? +data.nineq_score : data.nineq_score,
      extra_info: _.isEmpty(data.extra_info) ? null : data.extra_info,
      is_done: isDone,
    };
    await apiService
      .post(`/mental/mental_records/${recordId}/medical_record`, req)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === "success") {
          setIsLoading(false);
          setShowSuccessModal(true);

          enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" })
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        if (err.response?.status === 500) {
          enqueueSnackbar("เกิดข้อผิดพลาด ขณะนี้ระบบขัดข้อง", { variant: "error" })
        } else if (err.response?.status === 401) {
          enqueueSnackbar("คุณไม่ได้เข้าสู่ระบบ/session ไม่ถูกต้อง", { variant: "error" })
        } else {
          enqueueSnackbar(err.message, { variant: "error" })
        }
      });
  };

  const onClickCloseModal = () => {
    setShowSuccessModal(false);
    getRecordInfo(recordId);
    navigate(-1);
  };

  const formatDate = (pubDate) => {
    const dateStr = new Date(pubDate).toLocaleDateString("th-TH", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    const timeStr = format(new Date(pubDate), ", HH:mm");
    return dateStr + timeStr;
  };

  return (
    <Flex
      as="form"
      minHeight="100vh"
      pb={100}
      flexDirection="column"
      backgroundColor="#DEF6FF"
      fontFamily="SukhumvitSet-Medium"
      onSubmit={handleSubmit(onSubmit)}
    >
      <DashBoardHeader />
      <Flex flexDirection="column" px={36} width={1}>
        <Text mt={36} color="#003A66" fontWeight={700} fontSize="1.5rem">
          ประวัติผู้ติดต่อ
        </Text>
        <PatientInfoBox record={record} />
      </Flex>
      <hr style={{ marginTop: "36px", color: "#E0EEF6" }} />
      <Flex flexDirection="column" px={36} width={1}>
        <Text mt={36} color="#003A66" fontWeight={700} fontSize="1.5rem">
          ประวัติอาการ
        </Text>
        <PatientSymptomBox summary={summary} />
      </Flex>
      <hr style={{ marginTop: "36px", color: "#E0EEF6" }} />

      {/* Form */}
      <Flex width={1} justifyContent="center">
        <Text mt={32} color="#003A66" fontWeight={700} fontSize="1.5rem">
          ผลประเมินก่อนรับบริการ
        </Text>
      </Flex>
      <Flex flexDirection="column" px={36} width={1}>
        <PatientRiskScore
          register={register}
          disabled={
            watch("is_not_accept_call") || watch("invalid_phone_number")
          }
          errors={errors}
        />
      </Flex>
      <hr style={{ marginTop: "36px", color: "#E0EEF6" }} />
      <Flex mt={24} flexDirection="column" px={36} width={1}>
        <PatientContactCheckBox
          register={register}
          watch={watch}
          notAcceptedList={notAcceptedList}
        />
      </Flex>
      <hr style={{ marginTop: "36px", color: "#E0EEF6" }} />
      <Flex mt={24} flexDirection="column" px={36} width={1}>
        <Text mt={36} color="#003A66" fontWeight={700} fontSize="1.5rem">
          บันทึกเพิ่มเติม
        </Text>
        <Flex
          mt={24}
          flex={1}
          flexDirection="column"
          fontFamily="SukhumvitSet-Medium"
          css={{
            background: "#fff",
            boxShadow: "0px 0px 0px 2px rgba(197, 197, 197, 0.25)",
            borderRadius: "7px",
            padding: "1.6rem 2rem",
          }}
        >
          <Textarea
            {...register("extra_info", { required: false })}
            css={{
              fontFamily: "SukhumvitSet-Medium",
              border: "0.5px solid #919191",
              background: "#fff",
              borderRadius: "7px",
              ":focus": {
                outline: "none",
              },
            }}
            placeholder="เพิ่มบันทึก"
          />
          {/* Logs history */}
          <Text mt={24} fontSize="1.1rem" color="#206ED1" fontWeight={500}>
            ประวัติการบันทึก
          </Text>
          {_.isEmpty(record?.medical_logs) && (
            <Text mt={16} fontSize="1rem" fontWeight={500}>
              ไม่มีประวัติ
            </Text>
          )}
          {_.map(record?.medical_logs, (log, i) => (
            <Flex key={`log-${i}`} flexDirection="column">
              <Text
                pt={3}
                pb={1}
                fontSize="1rem"
                color="#09090A"
                fontWeight={500}
              >
                {`${formatDate(log.pub_date)} - ${log.prefix} ${
                  log.first_name
                } ${log.last_name} (${log.specialty})`}
              </Text>
              <Text
                pt={1}
                pb={3}
                fontSize="1rem"
                color="#09090A"
                fontWeight={500}
                css={{
                  borderBottom: "1px solid #7182A6",
                }}
              >
                {log.log_text}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Button
            type="submit"
            mt={4}
            width={240}
            height={64}
            disabled={disableButton}
            alignSelf="center"
            backgroundColor="#206ed1"
            fontFamily="SukhumvitSet-Medium"
            fontSize="1.3rem"
            css={{
              borderRadius: "14px",
              "&:hover": {
                backgroundColor: "#005ED2",
              },
            }}
          >
            บันทึกผล
          </Button>
          <Button
            type="submit"
            mt={4}
            ml={24}
            width={240}
            height={64}
            alignSelf="center"
            backgroundColor="#206ed1"
            fontFamily="SukhumvitSet-Medium"
            fontSize="1.3rem"
            css={{
              borderRadius: "14px",
              "&:hover": {
                backgroundColor: "#005ED2",
              },
            }}
            onClick={() => setIsDone(true)}
          >
            ปิดเคส
          </Button>
        </Flex>
      </Flex>

      {/* Success Modal */}
      <SuccessModal open={showSuccessModel} onClose={onClickCloseModal} />

      {/* Loading */}
      {isLoading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          width={1}
          height="100vh"
          sx={{
            position: "absolute",
            background: "rgba(255, 255, 255, 0.6)",
            backdropFilter: "blur(2px)",
            zIndex: 4,
          }}
        >
          <Player
            autoplay={true}
            loop={true}
            src={"https://assets9.lottiefiles.com/packages/lf20_earcnwm3.json"}
            style={{ height: "200px", width: "200px" }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default PatientInfoPage;
