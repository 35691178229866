import React from "react";
import { Flex, Text } from "rebass";

const severeLevel = {
  severe: {
    text: "Severe",
    color: "#FE274A",
    bgColor: "#FEEBEB",
  },
  moderate: {
    text: "Moderate",
    color: "#DF8900",
    bgColor: "#FFF4D9",
  },
  mild: {
    text: "Mild",
    color: "#7EC820",
    bgColor: "#CAFFF3",
  },
};

const StatisticBox = ({
  headerText,
  severeNumber,
  moderateNumber,
  mildNumber,
}) => {
  return (
    <Flex
      mt={36}
      py={20}
      minWidth={320}
      minHeight={203}
      flexDirection="column"
      css={{
        background: "#fff",
        border: "1px solid #D0DAE9",
        borderRadius: "8px",
        fontFamily: "SukhumvitSet-Medium",
      }}
    >
      <Text fontSize="1.2rem" color="#000" textAlign="center" fontWeight={700}>
        {headerText}
      </Text>
      <Flex mt={24} height={90} width={1}>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          fontSize="1rem"
          flexBasis="33%"
          css={{
            borderRight: "1px solid #D0DAE9",
            lineHeight: "30px",
          }}
        >
          {severeLevel.severe.text}
          <br />
          <span
            style={{
              color: severeLevel.severe.color,
              fontSize: "1.2rem"
            }}
          >
            {severeNumber}
          </span>
        </Flex>
        <Flex
          flex={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          fontSize="1rem"
          flexBasis="33%"
          css={{
            borderRight: "1px solid #D0DAE9",
            lineHeight: "30px",
          }}
        >
          {severeLevel.moderate.text}
          <br />
          <span
            style={{
              color: severeLevel.moderate.color,
              lineHeight: "30px",
              fontSize: "1.2rem"
            }}
          >
            {moderateNumber}
          </span>
        </Flex>
        <Flex
          flex={1}
          minWidth={101}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          fontSize="1rem"
          flexBasis="33%"
        >
          {severeLevel.mild.text}
          <br />
          <span
            style={{
              color: severeLevel.mild.color,
              borderRight: "1px solid #fff",
              lineHeight: "30px",
              fontSize: "1.2rem"
            }}
          >
            {mildNumber}
          </span>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default StatisticBox;
